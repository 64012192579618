export const handleError = (action) => {
  const { payload } = action;

  if (!payload) {
    return [];
  }

  let retErrors = [];
  const message =
    "Estamos teniendo problemas para conectarnos con el servidor, por favor inténtelo nuevamente.";
  const type = "Internal";

  if (payload.data) {
    if (Array.isArray(payload.data)) {
      payload.data.forEach(
        (element) => element.errors && retErrors.push(...element.errors),
      );
    } else {
      retErrors = payload.data.errors;
    }
  } else if (payload.response && payload.response.data) {
    if (payload.response.data.errors) {
      retErrors = payload.response.data.errors;
    } else {
      retErrors = [
        {
          message,
          type,
        },
      ];
    }
  } else if (
    payload.request &&
    !payload.request.status.toString().startsWith("2")
  ) {
    // La request se realizó pero no se obtuvo ninguna respuesta
    retErrors = [
      {
        message,
        type,
      },
    ];
  }

  // Puede ser que algún endpoint no devuelva el array de errores
  // por lo que generamos uno vacío para no romper con el formato
  retErrors = Array.isArray(retErrors) ? retErrors : [];

  return retErrors;
};

export const didInvalidate = (action) => {
  return handleError(action).length !== 0;
};
