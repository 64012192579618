import { Button, makeStyles, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFormPrestaciones } from "../../../../actions/forms";
import PrestacionesAdd from "./prestacionesAdd";
import PrestacionesCard from "./prestacionesCard";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flex: 1,
  },
  prestacionesErrorText: {
    marginBottom: 10,
  },
}));

const prestacionModel = {
  id: null,
  cantidad: 1,
  zonaAfectada: null,
  subnomenclador: null,
};

const PrestacionesContainer = ({
  prestaciones,
  setPrestaciones,
  disabled,
  configuration,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [openAdd, setOpenAdd] = useState(false);

  const prestacionesState = useSelector((state) => state.forms.prestaciones);

  const maxPrestaciones =
    configuration.maxAmountOfPrestaciones !== 0 &&
    prestaciones.length >= configuration.maxAmountOfPrestaciones;

  const minPrestaciones =
    configuration.minAmountOfPrestaciones !== 0 &&
    prestaciones.length < configuration.minAmountOfPrestaciones;

  const handleAdd = (value) => {
    let prestacionExisted = prestaciones.find(
      (p) => p.id === value.id && p.zonaAfectada === value.zonaAfectada,
    );

    if (!prestacionExisted) {
      prestaciones.push(value);
    } else {
      prestacionExisted.cantidad = value.cantidad;
    }
    handleCloseAdd();
  };

  const handleDelete = (prestacion) => {
    setPrestaciones(
      prestaciones.filter(
        (p) =>
          p.id !== prestacion.id || p.zonaAfectada !== prestacion.zonaAfectada,
      ),
    );
  };

  const handleOpenAdd = () => {
    if (!prestacionesState.data.prestaciones && !prestacionesState.isFetching) {
      dispatch(getFormPrestaciones());
    }
    setOpenAdd(true);
  };

  const handleCloseAdd = () => {
    setOpenAdd(false);
  };

  return (
    <div className={classes.container}>
      <PrestacionesCard
        prestaciones={prestaciones}
        disabled={disabled}
        handleDelete={handleDelete}
      />
      {maxPrestaciones && (
        <Typography
          variant="body2"
          color="error"
          className={classes.prestacionesErrorText}>
          Límite máximo de prestaciones para la ART alcanzado
        </Typography>
      )}
      {minPrestaciones && (
        <Typography
          variant="body2"
          color="error"
          className={classes.prestacionesErrorText}>
          {`Debe ingresar al menos ${configuration.minAmountOfPrestaciones}  ${
            configuration.minAmountOfPrestaciones > 1
              ? "prestaciones"
              : "prestación"
          }`}
        </Typography>
      )}
      <Button
        color="primary"
        variant="outlined"
        onClick={handleOpenAdd}
        disabled={disabled || maxPrestaciones}>
        Agregar prestación
      </Button>
      <PrestacionesAdd
        prestacion={prestacionModel}
        open={openAdd}
        handleClose={handleCloseAdd}
        handleSubmit={handleAdd}
      />
    </div>
  );
};

export default PrestacionesContainer;
