import React from "react";
import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Activate from "../../commons/setPassword/setPassword";
import Logo from "../../../assets/images/logo_medium.png";
import LoginFooter from "../login/loginFooter";

const useStyles = makeStyles((theme) => ({
  text: {
    color: "#303030",
    fontFamily: "Roboto, sans-serif",
    fontWeight: "bold",
    fontSize: "medium",
  },
  gridContainer: {
    flex: 1,
    alignItems: "center",
    backgroundImage: "linear-gradient(120deg, #96d43a, #539aff)",
  },
  logo: {
    paddingBottom: "60px",
  },
  loginCard: {
    minWidth: "450px",
    backgroundColor: "white",
    boxShadow: "0px 0px 5px 0px grey",
    borderRadius: "10px",
    padding: "30px 25px 10px 25px",
  },
}));

function ActivateAccount() {
  const classes = useStyles();

  return (
    <Grid container justify="center" className={classes.gridContainer}>
      <Grid item xs={4} style={{ marginBottom: 50, marginTop: 50 }}>
        <Card className={classes.loginCard}>
          <CardContent>
            <Grid
              container
              justify="center"
              alignItems="center"
              direction="column"
            >
              <img src={Logo} className={classes.logo} alt="" />
            </Grid>
            <Typography className={classes.text}>
              Confirmación de cuenta
            </Typography>
            <Activate type="ActivateAccount" />
          </CardContent>
          <LoginFooter loginAccount></LoginFooter>
        </Card>
      </Grid>
    </Grid>
  );
}

export default ActivateAccount;