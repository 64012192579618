import { UNAUTHORIZED_REQUEST, USER_LOGIN } from "../actions/login";

const INITIAL_STATE = {
  isFetching: false,
  didInvalidate: false,
  token: false,
  roles: [],
  userName: "",
  unauthorizedRequest: false,
};

const auth = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case `${USER_LOGIN}_REJECTED`:
      return {
        ...state,
        isFetching: false,
        didInvalidate: true,
        token: false,
      };
    case `${USER_LOGIN}_PENDING`:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      };
    case `${USER_LOGIN}_FULFILLED`: {
      const roles = {
        [`${action.payload.roles.nombre}`]: true,
        tipo: action.payload.roles.tipo,
      };
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        token: action.payload.token,
        roles,
        userName: action.payload.userName,
      };
    }
    case UNAUTHORIZED_REQUEST: {
      return {
        ...state,
        unauthorizedRequest: true,
      };
    }

    default:
      return state;
  }
};

export default auth;
