import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearAppointmentAssist,
  getDocuments,
  openAppointmentsGrid,
} from "../../../../actions/accidentDashboard";
import {
  correctConnectivityErrorAction,
  getAllConnectivityErrors,
  getConnectivityErrors,
} from "../../../../actions/connectivityResponse";
import {
  clearFpt,
  getFormProfessionalSpecialties,
  getFormProfessionals,
  postForm,
  setFpt,
} from "../../../../actions/forms";
import {
  convertDateTimeToNet,
  validateDateRange,
} from "../../../../utils/datetimeUtils";
import CircularLoading from "../../../commons/loadingData/circularLoading";
import Iami from "./iami";

const initialIamiModel = {
  nombreTipoFormulario: null,
  tipoSiniestro: "",
  fechaHoraAccidente: null,
  fechaHoraAbondonoTrabajo: null,
  fechaPrimeraCuracion: null,
  fechaHoraProximaConsulta: null,
  reagravacion: false,
  tipoReingreso: null,
  fechaReagravacion: null,
  fechaAtencionReagravacion: null,
  comoOcurrioAccidente: "",
  diagnosticoOMS: null,
  diagnostico: "",
  formaAccidente: null,
  agenteCausante: null,
  naturalezaLesion: null,
  zonaAfectada: null,
  gravedad: "",
  atencionMedicaAnterior: false,
  centroAsistencial: "",
  tratamiento: "",
  bajaLaboral: false,
  diasEstimados: "",
  sugerenciaRechazo: false,
  causaRechazo: null,
  suponeIncapacidad: false,
  manoHabil: null,
  r79: false,
  profesionalApellidoNombre: null,
  profesionalMnMp: "",
  especialidadProfesional: "",
  especialidadInforme: "",
  informacionSensible: false,
};

const appointmentModel = {
  fechaHora: null,
  especialidad: null,
  profesional: null,
  profesionalApellidoNombre: null,
  matricula: "",
  observacion: "",
  traslado: false,
  fromDB: false,
};

const IamiContainer = ({
  handleClose,
  isView,
  setLoaded,
  handleOpenConfirmDialog,
  fromErroresConectividad,
  fromErrorDialog,
  selectedDocument,
}) => {
  const dispatch = useDispatch();

  const accident = useSelector((state) => state.accidentDashboard.accident);
  const saveForm = useSelector((state) => state.forms.saveForm);
  const model = useSelector((state) => state.forms.model);
  const form = useSelector((state) => state.forms.form);
  const fpt = useSelector((state) => state.forms.fpt);
  const diagnosis = useSelector((state) => state.forms.diagnosis);
  const professionals = useSelector((state) => state.forms.professionals);
  const calendar = useSelector(
    (state) => state.accidentDashboard.appointmentsCalendar,
  );
  const professionalSpecialties = useSelector(
    (state) => state.forms.professionalSpecialties,
  );
  const documentation = useSelector(
    (state) => state.accidentDashboard.availableDocumentsToAdd,
  );
  const appointmentsPostForm = useSelector(
    (state) => state.accidentDashboard.appointmentsPostForm,
  );
  const time = useSelector(
    (state) => state.connectivityResponse.connectivityErrors.time,
  );
  const [iamiModel, setIamiModel] = useState(null);
  const [files, setFiles] = useState([]);
  const [appointment, setAppointment] = useState(appointmentModel);
  const [openAppointment, setOpenAppointment] = useState(false);

  const isLoading =
    model.isFetching ||
    saveForm.isFetching ||
    form.isFetching ||
    diagnosis.isFetching ||
    professionals.isFetching ||
    (isView && calendar.isFetching) ||
    (!isView && !iamiModel);

  useEffect(() => {
    if (isView && !form.isFetching) {
      setFiles(form.data.form.adjuntos);
      if (form.data.form.turno) {
        setAppointment(form.data.form.turno);
      } else {
        form.data.form.turno = appointmentModel;
        setAppointment(appointmentModel);
      }
    }
  }, [form.isFetching]);

  useEffect(() => {
    if (!model.isFetching && !isView) {
      setIamiModel((prevState) => ({
        ...initialIamiModel,
        fechaPrimeraCuracion: new Date(),
        fechaHoraAccidente: model.data.fields.fechaHoraAccidente.options
          ? new Date(model.data.fields.fechaHoraAccidente.options)
          : initialIamiModel.fechaHoraAccidente,
        fechaReagravacion: model.data.fields.fechaReagravacion.options
          ? new Date(model.data.fields.fechaReagravacion.options)
          : initialIamiModel.fechaReagravacion,
        reagravacion: model.data.fields.fechaReagravacion.options
          ? true
          : initialIamiModel.reagravacion,
        gravedad: model.data.fields.gravedad.options.includes("Leve")
          ? "Leve"
          : "",
        naturalezaLesion: model.data.fields.naturalezaLesion
          ? model.data.fields.naturalezaLesion.options
          : prevState.naturalezaLesion,
        zonaAfectada: model.data.fields.zonaAfectada
          ? model.data.fields.zonaAfectada.options
          : prevState.zonaAfectada,
        diagnosticoOMS: model.data.fields.diagnosticoOMS
          ? model.data.fields.diagnosticoOMS.options
          : prevState.diagnosticoOMS,
        profesionalApellidoNombre: model.data.fields.profesionalApellidoNombre
          .options
          ? model.data.fields.profesionalApellidoNombre.options
          : initialIamiModel.profesionalApellidoNombre,
        nombreTipoFormulario:
          model.data.fields.tiposFormulario.options.length === 1
            ? model.data.fields.tiposFormulario.options[0].nombre
            : initialIamiModel.nombreTipoFormulario,
      }));
    }
  }, [model.isFetching]);

  useEffect(() => {
    if (accident.mlCloudCoreArtId) {
      dispatch(getFormProfessionalSpecialties(accident.mlCloudCoreArtId));
    }
  }, [accident.mlCloudCoreArtId]);

  useEffect(() => {
    if (!isLoading && !!setLoaded) {
      setLoaded(true);
    }
  }, [
    model.isFetching,
    saveForm.isFetching,
    form.isFetching,
    diagnosis.isFetching,
    professionals.isFetching,
    calendar.isFetching,
  ]);

  const handleSubmit = (form) => {
    if (!isView) {
      var appointmentsInRange = calendar.data.appointments?.filter(
        (d) =>
          validateDateRange(d.fechaHora, form, null, model) &&
          (d.estado === "Programados" || d.estado === "No informados"),
      );
    }
    if (
      appointmentsInRange &&
      !appointmentsPostForm.wasSent &&
      appointmentsInRange.length != 0
    ) {
      dispatch(openAppointmentsGrid(form));
      if (fpt.wasCanceled) {
        dispatch(clearFpt);
      }
    } else {
      const filesAux = [];
      files.forEach((e) =>
        filesAux.push({
          ...e,
          nombre: e.filename || e.nombre,
          base64: e.value,
        }),
      );
      const model = {
        ...form,
        numeroSiniestroPrestador: accident.idSiniestro,
        adjuntos: filesAux,
        turno: null,
        fechaHoraAccidente: convertDateTimeToNet(form.fechaHoraAccidente),
        fechaHoraAbondonoTrabajo: convertDateTimeToNet(
          form.fechaHoraAbondonoTrabajo,
        ),
        fechaPrimeraCuracion: convertDateTimeToNet(form.fechaPrimeraCuracion),
        fechaHoraProximaConsulta: convertDateTimeToNet(
          form.fechaHoraProximaConsulta,
        ),
        fechaReagravacion: convertDateTimeToNet(form.fechaReagravacion),
        fechaAtencionReagravacion: convertDateTimeToNet(
          form.fechaAtencionReagravacion,
        ),
      };

      if (appointment.fechaHora !== null) {
        model.turno = {
          ...appointment,
          fechaHora: convertDateTimeToNet(appointment.fechaHora),
        };
      }
      if (fromErroresConectividad) {
        model.numeroSiniestroPrestador = selectedDocument.siniestro;
      }
      dispatch(postForm("iami", model, accident.mlCloudCoreArtId))
        .then(() => {
          dispatch(getFormProfessionals(accident.mlCloudCoreArtId));
        })
        .then(() => {
          if (fromErrorDialog) {
            dispatch(
              correctConnectivityErrorAction(
                selectedDocument.idRespError,
                accident.mlCloudCoreArtId,
              ),
            ).then((res) => {
              dispatch(
                getDocuments(accident.idSiniestro, accident.mlCloudCoreArtId),
              );
              dispatch(
                getConnectivityErrors(
                  selectedDocument.nroTransaccion,
                  accident.mlCloudCoreArtId,
                ),
              ).then((res) => handleClose(true, false));
            });
          } else {
            handleClose(true, false);
          }

          if (fromErroresConectividad) {
            dispatch(
              correctConnectivityErrorAction(
                selectedDocument.idRespError,
                accident.mlCloudCoreArtId,
              ),
            ).then((res) => {
              dispatch(getAllConnectivityErrors(time));
            });
          }
        })
        .catch(() => handleClose(true, true))
        .finally(() => {
          if (
            appointment.traslado &&
            !appointment.fromDB &&
            documentation.data.find((f) => f.formulario === "Fpt")
              ? true
              : false
          ) {
            const especialidad = professionalSpecialties.data.find(
              (e) => e.id == appointment.especialidad,
            );
            var appointmentAux = {
              ...appointment,
              especialidadNombre: especialidad.nombre,
            };
            dispatch(setFpt({ ...form, ...appointmentAux }));
          }
          dispatch(clearAppointmentAssist());
          setAppointment(appointmentModel);
        });
    }
  };

  useEffect(() => {
    if (appointmentsPostForm.wasSent) {
      handleSubmit(appointmentsPostForm.data);
    }
  }, [appointmentsPostForm.wasSent]);

  const handleAppointmentSubmit = (appointment) => {
    setAppointment(appointment);
    setOpenAppointment(false);
  };

  return (
    <div style={{ width: "100%" }}>
      {isLoading ? (
        <div style={{ marginTop: 100 }}>
          <CircularLoading />
        </div>
      ) : isView ? (
        <Iami
          handleClose={handleClose}
          files={files}
          setFiles={setFiles}
          handleSubmit={handleSubmit}
          model={form.data.model}
          iamiModel={form.data.form}
          roles={form.data.roles}
          selectedDocument={selectedDocument}
          openAppointment={openAppointment}
          handleAppointmentSubmit={handleAppointmentSubmit}
          handleCloseAppointment={() => setOpenAppointment(false)}
          handleOpenAppointment={() => setOpenAppointment(true)}
          appointmentModel={appointment}
          fromErrorDialog={fromErrorDialog}
          fromErroresConectividad={fromErroresConectividad}
          isView={isView}
        />
      ) : (
        <Iami
          handleClose={handleOpenConfirmDialog}
          files={files}
          setFiles={setFiles}
          handleSubmit={handleSubmit}
          model={model.data}
          iamiModel={iamiModel}
          openAppointment={openAppointment}
          handleAppointmentSubmit={handleAppointmentSubmit}
          handleCloseAppointment={() => setOpenAppointment(false)}
          handleOpenAppointment={() => setOpenAppointment(true)}
          appointmentModel={appointment}
          isView={isView}
        />
      )}
    </div>
  );
};

export default IamiContainer;
