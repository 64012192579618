export default {
  //Desa
  //baseUrl: "https://srvdev.accessinformatica.com.ar:7060/core/api/",
  //Test
  // baseUrl: "https://point.mlconectividad.com.ar:9002/api/",
  //Prod
  baseUrl: "https://point.mlconectividad.com.ar:9003/api/",
  //Local
  // baseUrl: "http://localhost:56980/api/",
};
