import { makeStyles } from "@material-ui/core";
import "./formStyle.css";

export const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  fieldContainer: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: 40,
    paddingRight: 40,
    paddingBottom: 20,
    overflow: "auto",
    height: "calc(100vh - 166px)",
  },
  actionsContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    zIndex: 2,
    backgroundColor: "white",
    paddingRight: 10,
    boxShadow: "0px 0 5px lightGrey",
  },
  rowContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 15,
  },
  leftColumnContainer: {
    display: "flex",
    width: "47%",
  },
  rightColumnContainer: {
    display: "flex",
    justifyContent: "flex-end",
    width: "47%",
  },
  rightColumnContainerCheckBoxStart: {
    display: "flex",
    justifyContent: "flex-start",
    width: "47%",
  },
  input: {
    flex: 1,
  },
  appointmentControl: {
    display: "flex",
    alignItems: "center",
    flex: 1,
  },
  addAppointment: {
    marginLeft: 5,
    width: 50,
    height: 50,
    color: theme.palette.primary.main,
    cursor: "pointer",
  },
  addAppointmentDisabled: {
    marginLeft: 5,
    width: 50,
    height: 50,
    color: "grey",
  },
}));
