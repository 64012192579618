import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearAppointmentAssist,
  getDocuments,
  openAppointmentsGrid,
} from "../../../../actions/accidentDashboard";
import {
  correctConnectivityErrorAction,
  getAllConnectivityErrors,
  getConnectivityErrors,
} from "../../../../actions/connectivityResponse";
import { getFormProfessionals, postForm } from "../../../../actions/forms";
import {
  convertDateTimeToNet,
  validateDateRange,
} from "../../../../utils/datetimeUtils";
import CircularLoading from "../../../commons/loadingData/circularLoading";
import Am from "./am";

const initialAmModel = {
  nombreTipoFormulario: null,
  fechaAlta: null,
  fechaReinicioLaboral: null,
  diagnosticoOMS: null,
  diagnostico: "",
  observaciones: "",
  sugerenciaRecalificacion: false,
  actividadHabitual: false,
  tipoAlta: "",
  incapacidad: "",
  prestacionesMantenimiento: false,
  profesionalApellidoNombre: null,
  profesionalMnMp: "",
  diasReinicioLaboral: 0,
  especialidadProfesional: "",
  marcaFirmado: "Si",
  firmaConforme: null,
  especialidadInforme: "",
  informacionSensible: false,
};

const AmContainer = ({
  handleClose,
  isView,
  setLoaded,
  handleOpenConfirmDialog,
  selectedDocument,
  fromErroresConectividad,
  fromErrorDialog,
}) => {
  const dispatch = useDispatch();

  const appointmentsPostForm = useSelector(
    (state) => state.accidentDashboard.appointmentsPostForm,
  );
  const accident = useSelector((state) => state.accidentDashboard.accident);
  const saveForm = useSelector((state) => state.forms.saveForm);
  const model = useSelector((state) => state.forms.model);
  const form = useSelector((state) => state.forms.form);
  const diagnosis = useSelector((state) => state.forms.diagnosis);
  const time = useSelector(
    (state) => state.connectivityResponse.connectivityErrors.time,
  );
  const professionals = useSelector((state) => state.forms.professionals);
  const specialtiesState = useSelector(
    (state) => state.forms.specialtiesDiagnosis,
  );
  const calendar = useSelector(
    (state) => state.accidentDashboard.appointmentsCalendar,
  );

  const [amModel, setAmModel] = useState(null);
  const [files, setFiles] = useState([]);
  const [especialidades, setEspecialidades] = useState(null);

  const isLoading =
    model.isFetching ||
    saveForm.isFetching ||
    form.isFetching ||
    diagnosis.isFetching ||
    especialidades === null ||
    professionals.isFetching ||
    (isView && specialtiesState.isFetching) ||
    (!isView && !amModel);

  useEffect(() => {
    if (isView && !form.isFetching) {
      setFiles(form.data.form.adjuntos);
      setEspecialidades(form.data.form.especialidadesAm);
    } else if (!form.isFetching) {
      setEspecialidades([]);
    }
  }, [form.isFetching]);

  useEffect(() => {
    if (!model.isFetching && !isView) {
      setAmModel(() => ({
        ...initialAmModel,
        fechaAlta: new Date(),
        diagnosticoOMS: model.data.fields.diagnosticoOMS
          ? model.data.fields.diagnosticoOMS.options
          : initialAmModel.diagnosticoOMS,
        diagnostico: model.data.fields.diagnostico.options
          ? model.data.fields.diagnostico.options
          : initialAmModel.diagnostico,
        fechaReinicioLaboral: model.data.configuration
          .fechaReinicioLaboralDefault
          ? model.data.fields.diasReinicioLaboral.options
            ? moment(new Date()).add(
                model.data.fields.diasReinicioLaboral.options,
                "days",
              )
            : initialAmModel.fechaReinicioLaboral
          : initialAmModel.fechaReinicioLaboral,
        profesionalApellidoNombre: model.data.fields.profesionalApellidoNombre
          .options
          ? model.data.fields.profesionalApellidoNombre.options
          : initialAmModel.profesionalApellidoNombre,
        nombreTipoFormulario:
          model.data.fields.tiposFormulario.options.length === 1
            ? model.data.fields.tiposFormulario.options[0].nombre
            : initialAmModel.nombreTipoFormulario,
      }));
    }
  }, [model.isFetching]);

  useEffect(() => {
    if (!isLoading && !!setLoaded) {
      setLoaded(true);
    }
  }, [
    model.isFetching,
    saveForm.isFetching,
    form.isFetching,
    diagnosis.isFetching,
    professionals.isFetching,
    specialtiesState.isFetching,
    especialidades,
  ]);

  const handleSubmit = (form) => {
    if (!isView) {
      var appointmentsInRange = calendar.data.appointments?.filter(
        (d) =>
          validateDateRange(d.fechaHora, form, null, model) &&
          d.estado === "Programados",
      );
    }
    if (
      appointmentsInRange &&
      !appointmentsPostForm.wasSent &&
      appointmentsInRange.length != 0
    ) {
      dispatch(openAppointmentsGrid(form));
    } else {
      const filesAux = [];
      files.forEach((e) =>
        filesAux.push({
          ...e,
          nombre: e.filename || e.nombre,
          base64: e.value,
        }),
      );
      const model = {
        ...form,
        numeroSiniestroPrestador: accident.idSiniestro,
        adjuntos: filesAux,
        fechaAlta: convertDateTimeToNet(form.fechaAlta),
        fechaReinicioLaboral: convertDateTimeToNet(form.fechaReinicioLaboral),
        especialidadesAm: especialidades,
      };
      if (fromErroresConectividad) {
        model.numeroSiniestroPrestador = selectedDocument.siniestro;
      }
      dispatch(postForm("am", model, accident.mlCloudCoreArtId))
        .then(() => {
          dispatch(getFormProfessionals(accident.mlCloudCoreArtId));
        })
        .then(() => {
          if (fromErrorDialog) {
            dispatch(
              correctConnectivityErrorAction(
                selectedDocument.idRespError,
                accident.mlCloudCoreArtId,
              ),
            ).then((res) => {
              dispatch(
                getDocuments(accident.idSiniestro, accident.mlCloudCoreArtId),
              );
              dispatch(
                getConnectivityErrors(
                  selectedDocument.nroTransaccion,
                  accident.mlCloudCoreArtId,
                ),
              ).then((res) => handleClose(true, false));
            });
          } else {
            handleClose(true, false);
          }

          if (fromErroresConectividad) {
            dispatch(
              correctConnectivityErrorAction(
                selectedDocument.idRespError,
                accident.mlCloudCoreArtId,
              ),
            ).then((res) => {
              dispatch(getAllConnectivityErrors(time));
            });
          }
        })
        .catch(() => handleClose(true, true));
      dispatch(clearAppointmentAssist());
    }
  };

  useEffect(() => {
    if (appointmentsPostForm.wasSent) {
      handleSubmit(appointmentsPostForm.data);
    }
  }, [appointmentsPostForm.wasSent]);

  return (
    <div style={{ width: "100%" }}>
      {isLoading ? (
        <div style={{ marginTop: 100 }}>
          <CircularLoading />
        </div>
      ) : isView ? (
        <Am
          handleClose={handleClose}
          files={files}
          setFiles={setFiles}
          handleSubmit={handleSubmit}
          model={form.data.model}
          amModel={form.data.form}
          roles={form.data.roles}
          selectedDocument={selectedDocument}
          especialidades={especialidades}
          fromErrorDialog={fromErrorDialog}
          fromErroresConectividad={fromErroresConectividad}
          setEspecialidades={setEspecialidades}
          isView={isView}
        />
      ) : (
        <Am
          handleClose={handleOpenConfirmDialog}
          files={files}
          setFiles={setFiles}
          handleSubmit={handleSubmit}
          model={model.data}
          amModel={amModel}
          especialidades={especialidades}
          setEspecialidades={setEspecialidades}
          isView={isView}
        />
      )}
    </div>
  );
};

export default AmContainer;
