import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { signoutUser } from "../../actions/login";
import config from "../../config/index";
import history from "../../history";
import AuthRoutes from "./authRoutes";
import PublicRoutes from "./publicRoutes";

const RoutesContainer = () => {
  const dispatch = useDispatch();

  const token = useSelector((state) => state.auth.token);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios.defaults.baseURL = config.baseUrl;

    if (token) {
      axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    } else {
      dispatch(signoutUser());
    }
    setLoading(false);
  }, []);

  if (loading) return <div />;

  return (
    <BrowserRouter history={history}>
      {!token ? <PublicRoutes /> : <AuthRoutes />}
    </BrowserRouter>
  );
};

export default RoutesContainer;
