import {
  Button,
  CardContent,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { loginUser } from "../../../actions/login";
import Logo from "../../../assets/images/logo_medium.png";
import FormikTextField from "../../commons/formik/formikTextField";
import LoginFooter from "../login/loginFooter";

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    backgroundImage: "linear-gradient(120deg, #96d43a, #539aff)",
  },
  gridRowItem: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  gridItemAligned: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: 10,
  },
  logo: {
    paddingBottom: "40px",
  },
  loginCard: {
    minWidth: "450px",
    backgroundColor: "white",
    boxShadow: "0px 0px 5px 0px grey",
    borderRadius: "10px",
    padding: "30px 25px 10px 25px",
  },
  button: {
    marginTop: 20,
    backgroundColor: `${theme.palette.primary.gradientLogin2}`,
  },
}));

const validationSchema = yup.object({
  userName: yup
    .string()
    .email("Dirección de correo inválida")
    .required("Campo obligatorio"),
  password: yup.string().required("Campo obligatorio"),
});

const initialValues = {
  // notese la N mayúscula en userName, ya que el back
  // espera el campo "userName", no "username" todo en minúscula.
  userName: "",
  password: "",
};

const LoginContainer = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loading = useSelector((state) => state.auth.isFetching);

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmitUser = (values) => {
    dispatch(loginUser(values, values.UserName))
      .then(() => navigate("/home"))
      .catch((error) => {});
  };

  return (
    <Grid container justifyContent="center" className={classes.gridContainer}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmitUser}>
        <Paper className={classes.loginCard}>
          <Form className={classes.form}>
            <CardContent>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                direction="column">
                <img src={Logo} className={classes.logo} alt="" />
              </Grid>
              <Grid container direction={"row"} spacing={2} nowrap="true">
                <Grid item xs={12} className={classes.gridRowItem}>
                  <AccountCircleIcon className={classes.gridItemAligned} />
                  <FormikTextField
                    variant="outlined"
                    className={classes.gridItemAligned}
                    margin="normal"
                    fullWidth
                    label="Usuario"
                    name="userName"
                    autoFocus
                    type="text"
                  />
                </Grid>
              </Grid>
              <Grid container direction={"row"} spacing={2} alignItems="center">
                <Grid item xs={12} className={classes.gridRowItem}>
                  <LockOutlinedIcon className={classes.gridItemAligned} />
                  <FormikTextField
                    variant="outlined"
                    className={classes.gridItemAligned}
                    margin="normal"
                    fullWidth
                    name="password"
                    label="Contraseña"
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={togglePasswordVisibility}
                            edge="end">
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
              <Button
                color="primary"
                variant="contained"
                className={classes.button}
                fullWidth
                type="submit"
                disabled={loading}>
                {loading ? (
                  <CircularProgress size={24} thickness={4} color="secondary" />
                ) : (
                  "Ingresar"
                )}
              </Button>
            </CardContent>
          </Form>
          <LoginFooter register passwordRecovery></LoginFooter>
        </Paper>
      </Formik>
    </Grid>
  );
};

export default LoginContainer;
