import { FormControlLabel, MenuItem, Radio } from "@material-ui/core";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import GooglePlacesAutocomplete from "../../../commons/formComponents/GooglePlacesAutocomplete";
import FormikActions from "../../../commons/formik/formikActions";
import FormikAutocomplete from "../../../commons/formik/formikAutocomplete";
import FormikDateTimePicker from "../../../commons/formik/formikDateTimePicker";
import FormikRadio from "../../../commons/formik/formikRadio";
import FormikSelect from "../../../commons/formik/formikSelect";
import FormikTextField from "../../../commons/formik/formikTextField";
import FormikTextFieldSpeech from "../../../commons/formik/formikTextFieldSpeech";
import DropZone from "../dropZone/dropZone";
import FormSectionDivider from "../formSectionDivider";
import { useStyles } from "../formStyle";
import { fptValidation, validateRequiredFields } from "../formValidation";

const Fpt = ({
  handleClose,
  files,
  setFiles,
  handleSubmit,
  model,
  fptModel,
  roles,
  selectedDocument,
  idArt,
  fromErrorDialog,
  fromErroresConectividad,
  disableDateUpdate,
}) => {
  const classes = useStyles();
  const [attachmentError, setAttachmentError] = useState(false);
  const [direccionesHabitualesDesde, setDireccionesHabitualesDesde] = useState([
    ...model.fields.direccionesHabituales.options,
  ]);
  const [direccionesHabitualesHasta, setDireccionesHabitualesHasta] = useState([
    ...model.fields.direccionesHabituales.options,
  ]);

  const fields = model.fields;
  const disabled =
    roles && !fromErrorDialog && !fromErroresConectividad
      ? !roles.editar
      : false;
  const fechaHoraSolicitudMinDate = fields.fechaHoraSolicitud.validations
    ? fields.fechaHoraSolicitud.validations.minDate
    : undefined;

  const setDireccionValue = (setFieldValue, field, value) => {
    setFieldValue(field, value ? value : "");
  };

  const setDireccion = (setFieldValue, value, isDesde) => {
    const aux = isDesde ? "Desde" : "Hasta";

    setDireccionValue(setFieldValue, `calle${aux}`, value.calle);
    setDireccionValue(setFieldValue, `numero${aux}`, value.numero);
    setDireccionValue(setFieldValue, `localidad${aux}`, value.localidad);
    setDireccionValue(setFieldValue, `provincia${aux}`, value.provincia);
    setDireccionValue(setFieldValue, `codigoPostal${aux}`, value.codigoPostal);
    setDireccionValue(
      setFieldValue,
      `tipoDireccion${aux}`,
      value.tipoDireccion,
    );
  };

  return (
    <Formik
      initialValues={fptModel}
      validationSchema={() => fptValidation(fechaHoraSolicitudMinDate)}
      validate={(values) => validateRequiredFields(values, fields)}
      onSubmit={(values) => {
        if (model.configuration.requiresAttachment && files.length === 0) {
          setAttachmentError(true);
        } else {
          handleSubmit(values, idArt);
        }
      }}>
      {({ values, setFieldValue }) => (
        <Form className={classes.container}>
          <div className={classes.fieldContainer}>
            <FormSectionDivider title="Traslado" />
            <div className={classes.rowContainer}>
              <div className={classes.leftColumnContainer}>
                <FormikDateTimePicker
                  name="fechaHoraSolicitud"
                  label={`Solicitado el ${
                    fields.fechaHoraSolicitud.required ? "*" : ""
                  }`}
                  disableFuture
                  inputVariant="outlined"
                  className={classes.input}
                  disabled={disabled || disableDateUpdate}
                  minDate={fechaHoraSolicitudMinDate}
                  autoComplete="off"
                />
              </div>
              <div className={classes.rightColumnContainer}>
                <FormikDateTimePicker
                  name="fechaHoraTurno"
                  label={`Para el ${fields.fechaHoraTurno.required ? "*" : ""}`}
                  inputVariant="outlined"
                  className={classes.input}
                  disabled={disabled || disableDateUpdate}
                  minDate={
                    values.fechaHoraSolicitud
                      ? values.fechaHoraSolicitud
                      : undefined
                  }
                  autoComplete="off"
                />
              </div>
            </div>
            <div className={classes.rowContainer}>
              <div className={classes.leftColumnContainer}>
                <FormikSelect
                  name="tipoTransporteNombre"
                  value={fptModel.tipoTransporteNombre}
                  label={`Tipo de transporte ${
                    fields.tipoTransporteNombre.required ? "*" : ""
                  }`}
                  variant="outlined"
                  className={classes.input}
                  disabled={disabled}>
                  {fields.tipoTransporteNombre.options.map((ttn) => (
                    <MenuItem value={ttn.id} key={ttn.id}>
                      {ttn.nombre}
                    </MenuItem>
                  ))}
                </FormikSelect>
              </div>
              <div className={classes.rightColumnContainer}>
                <FormikSelect
                  name="tipoUrgenciaNombre"
                  value={fptModel.tipoUrgenciaNombre}
                  label={`Tipo de urgencia ${
                    fields.tipoUrgenciaNombre.required ? "*" : ""
                  }`}
                  variant="outlined"
                  className={classes.input}
                  disabled={disabled}>
                  {fields.tipoUrgenciaNombre.options.map((tun) => (
                    <MenuItem value={tun.id} key={tun.id}>
                      {tun.nombre}
                    </MenuItem>
                  ))}
                </FormikSelect>
              </div>
            </div>
            <div className={classes.rowContainer}>
              <div className={classes.leftColumnContainer}>
                <FormikAutocomplete
                  name="solicitadoPor"
                  label={`Solicitado por ${
                    fields.solicitadoPor.required ? "*" : ""
                  }`}
                  variant="outlined"
                  options={fields.solicitadoPor.options}
                  className={classes.input}
                  disabled={disabled}
                />
              </div>
              <div className={classes.rightColumnContainerCheckBoxStart}>
                <FormikRadio
                  name="esperaRetorno"
                  label={`Espera y retorno ${
                    fields.esperaRetorno.required ? "*" : ""
                  }`}
                  disabled={disabled}
                  row>
                  <FormControlLabel
                    value="Sí"
                    control={<Radio />}
                    label="Sí"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="No"
                    control={<Radio />}
                    label="No"
                    labelPlacement="end"
                  />
                </FormikRadio>
              </div>
            </div>
            <div className={classes.rowContainer}>
              <FormikTextFieldSpeech
                id="observaciones"
                name="observaciones"
                label={`Observaciones ${
                  fields.observaciones.required ? "*" : ""
                }`}
                type="text"
                variant="outlined"
                multiline
                rows={3}
                rowsMax={10}
                className={classes.input}
                disabled={disabled}
              />
            </div>
            <FormSectionDivider title="Dirección desde" />
            <div className={classes.rowContainer}>
              <FormikAutocomplete
                name="direccionHabitualDesde"
                label="Direcciones habituales"
                variant="outlined"
                type="search"
                autoComplete="off"
                options={direccionesHabitualesDesde}
                callBack={(value) => {
                  if (value) {
                    setDireccion(setFieldValue, value, true);

                    setDireccionesHabitualesHasta((prevState) =>
                      fields.direccionesHabituales.options.filter(
                        (d) => d.calle !== value.calle || d.tipoDireccion === 4,
                      ),
                    );
                  } else {
                    setDireccion(setFieldValue, {}, true);
                    setDireccionesHabitualesHasta([
                      ...fields.direccionesHabituales.options,
                    ]);
                  }
                }}
                className={classes.input}
                returnProp="calle"
                renderOption={(option) => {
                  if (option.tipoDireccion === 4) {
                    return "Otras";
                  } else {
                    return `${option.calle ? option.calle : ""} ${
                      option.numero ? option.numero : ""
                    } - ${option.localidad ? option.localidad : ""}, ${
                      option.provincia ? option.provincia : ""
                    }`;
                  }
                }}
                filterOptions={(option) =>
                  option.calle +
                  option.numero +
                  option.localidad +
                  option.provincia
                }
                labelOption={(option) => {
                  if (option.tipoDireccion === 4) {
                    return "Otras";
                  } else {
                    return `${option.calle ? option.calle : ""} ${
                      option.numero ? option.numero : ""
                    } - ${option.localidad ? option.localidad : ""}, ${
                      option.provincia ? option.provincia : ""
                    }`;
                  }
                }}
                disabled={disabled}
              />
            </div>
            <div className={classes.rowContainer}>
              <GooglePlacesAutocomplete
                handleSubmit={(value) =>
                  setDireccion(setFieldValue, value, true)
                }
                label="Buscar una dirección para autocompletar los campos"
                variant="outlined"
                type="search"
                autoComplete="off"
                fullWidth
                disabled={disabled}
              />
            </div>
            <div className={classes.rowContainer}>
              <div className={classes.leftColumnContainer}>
                <FormikTextField
                  name="calleDesde"
                  label={`Calle ${fields.calleDesde.required ? "*" : ""}`}
                  type="text"
                  variant="outlined"
                  className={classes.input}
                  disabled={disabled}
                />
              </div>
              <div className={classes.rightColumnContainer}>
                <FormikTextField
                  name="numeroDesde"
                  label={`Número ${fields.numeroDesde.required ? "*" : ""}`}
                  type="number"
                  variant="outlined"
                  className={classes.input}
                  disabled={disabled}
                />
              </div>
            </div>
            <div className={classes.rowContainer}>
              <div className={classes.leftColumnContainer}>
                <FormikTextField
                  name="pisoDesde"
                  label={`Piso ${fields.pisoDesde.required ? "*" : ""}`}
                  type="text"
                  variant="outlined"
                  className={classes.input}
                  disabled={disabled}
                />
              </div>
              <div className={classes.rightColumnContainer}>
                <FormikTextField
                  name="departamentoDesde"
                  label={`Departamento ${
                    fields.departamentoDesde.required ? "*" : ""
                  }`}
                  type="text"
                  variant="outlined"
                  className={classes.input}
                  disabled={disabled}
                />
              </div>
            </div>
            <div className={classes.rowContainer}>
              <div className={classes.leftColumnContainer}>
                <FormikTextField
                  name="entreCalleDesde"
                  label={`Entre calle ${
                    fields.entreCalleDesde.required ? "*" : ""
                  }`}
                  type="text"
                  variant="outlined"
                  className={classes.input}
                  disabled={disabled}
                />
              </div>
              <div className={classes.rightColumnContainer}>
                <FormikTextField
                  name="entreCalleDesde1"
                  label={`Y calle ${
                    fields.entreCalleDesde1.required ? "*" : ""
                  }`}
                  type="text"
                  variant="outlined"
                  className={classes.input}
                  disabled={disabled}
                />
              </div>
            </div>
            <div className={classes.rowContainer}>
              <div className={classes.leftColumnContainer}>
                <FormikTextField
                  name="localidadDesde"
                  label={`Localidad ${
                    fields.localidadDesde.required ? "*" : ""
                  }`}
                  type="text"
                  variant="outlined"
                  className={classes.input}
                  disabled={disabled}
                />
              </div>
              <div className={classes.rightColumnContainer}>
                <FormikTextField
                  name="provinciaDesde"
                  label={`Provincia ${
                    fields.provinciaDesde.required ? "*" : ""
                  }`}
                  type="text"
                  variant="outlined"
                  className={classes.input}
                  disabled={disabled}
                />
              </div>
            </div>
            <div className={classes.rowContainer}>
              <div className={classes.leftColumnContainer}>
                <FormikTextField
                  name="codigoPostalDesde"
                  label="Código postal *"
                  type="number"
                  variant="outlined"
                  className={classes.input}
                  disabled={disabled}
                />
              </div>
              <div className={classes.rightColumnContainer}>
                <FormikTextField
                  name="telefonosDesde"
                  label={`Teléfono ${
                    fields.telefonosDesde.required ? "*" : ""
                  }`}
                  type="text"
                  variant="outlined"
                  className={classes.input}
                  disabled={disabled}
                />
              </div>
            </div>
            <FormSectionDivider title="Dirección hasta" />
            <div className={classes.rowContainer}>
              <FormikAutocomplete
                name="direccionHabitualHasta"
                label="Direcciones habituales"
                variant="outlined"
                type="search"
                autoComplete="off"
                options={direccionesHabitualesHasta}
                callBack={(value) => {
                  if (value) {
                    setDireccion(setFieldValue, value, false);

                    setDireccionesHabitualesDesde((prevState) =>
                      fields.direccionesHabituales.options.filter(
                        (d) => d.calle !== value.calle || d.tipoDireccion === 4,
                      ),
                    );
                  } else {
                    setDireccion(setFieldValue, {}, false);
                    setDireccionesHabitualesDesde([
                      ...fields.direccionesHabituales.options,
                    ]);
                  }
                }}
                className={classes.input}
                returnProp="calle"
                renderOption={(option) => {
                  if (option.tipoDireccion === 4) {
                    return "Otras";
                  } else {
                    return `${option.calle ? option.calle : ""} ${
                      option.numero ? option.numero : ""
                    } - ${option.localidad ? option.localidad : ""}, ${
                      option.provincia ? option.provincia : ""
                    }`;
                  }
                }}
                filterOptions={(option) =>
                  option.calle +
                  option.numero +
                  option.localidad +
                  option.provincia
                }
                labelOption={(option) => {
                  if (option.tipoDireccion === 4) {
                    return "Otras";
                  } else {
                    return `${option.calle ? option.calle : ""} ${
                      option.numero ? option.numero : ""
                    } - ${option.localidad ? option.localidad : ""}, ${
                      option.provincia ? option.provincia : ""
                    }`;
                  }
                }}
                disabled={disabled}
              />
            </div>
            <div className={classes.rowContainer}>
              <GooglePlacesAutocomplete
                handleSubmit={(value) =>
                  setDireccion(setFieldValue, value, false)
                }
                label="Buscar una dirección para autocompletar los campos"
                variant="outlined"
                type="search"
                autoComplete="off"
                fullWidth
                disabled={disabled}
              />
            </div>
            <div className={classes.rowContainer}>
              <div className={classes.leftColumnContainer}>
                <FormikTextField
                  name="calleHasta"
                  label={`Calle ${fields.calleHasta.required ? "*" : ""}`}
                  type="text"
                  variant="outlined"
                  className={classes.input}
                  disabled={disabled}
                />
              </div>
              <div className={classes.rightColumnContainer}>
                <FormikTextField
                  name="numeroHasta"
                  label={`Número ${fields.numeroHasta.required ? "*" : ""}`}
                  type="number"
                  variant="outlined"
                  className={classes.input}
                  disabled={disabled}
                />
              </div>
            </div>
            <div className={classes.rowContainer}>
              <div className={classes.leftColumnContainer}>
                <FormikTextField
                  name="pisoHasta"
                  label={`Piso ${fields.pisoHasta.required ? "*" : ""}`}
                  type="text"
                  variant="outlined"
                  className={classes.input}
                  disabled={disabled}
                />
              </div>
              <div className={classes.rightColumnContainer}>
                <FormikTextField
                  name="departamentoHasta"
                  label={`Departamento ${
                    fields.departamentoHasta.required ? "*" : ""
                  }`}
                  type="text"
                  variant="outlined"
                  className={classes.input}
                  disabled={disabled}
                />
              </div>
            </div>
            <div className={classes.rowContainer}>
              <div className={classes.leftColumnContainer}>
                <FormikTextField
                  name="entreCalleHasta"
                  label={`Entre calle ${
                    fields.entreCalleHasta.required ? "*" : ""
                  }`}
                  type="text"
                  variant="outlined"
                  className={classes.input}
                  disabled={disabled}
                />
              </div>
              <div className={classes.rightColumnContainer}>
                <FormikTextField
                  name="entreCalleHasta1"
                  label={`Y calle ${
                    fields.entreCalleHasta1.required ? "*" : ""
                  }`}
                  type="text"
                  variant="outlined"
                  className={classes.input}
                  disabled={disabled}
                />
              </div>
            </div>
            <div className={classes.rowContainer}>
              <div className={classes.leftColumnContainer}>
                <FormikTextField
                  name="localidadHasta"
                  label={`Localidad ${
                    fields.localidadHasta.required ? "*" : ""
                  }`}
                  type="text"
                  variant="outlined"
                  className={classes.input}
                  disabled={disabled}
                />
              </div>
              <div className={classes.rightColumnContainer}>
                <FormikTextField
                  name="provinciaHasta"
                  label={`Provincia ${
                    fields.provinciaHasta.required ? "*" : ""
                  }`}
                  type="text"
                  variant="outlined"
                  className={classes.input}
                  disabled={disabled}
                />
              </div>
            </div>
            <div className={classes.rowContainer}>
              <div className={classes.leftColumnContainer}>
                <FormikTextField
                  name="codigoPostalHasta"
                  label="Código postal *"
                  type="number"
                  variant="outlined"
                  className={classes.input}
                  disabled={disabled}
                />
              </div>
              <div className={classes.rightColumnContainer}>
                <FormikTextField
                  name="telefonosHasta"
                  label={`Teléfono ${
                    fields.telefonosHasta.required ? "*" : ""
                  }`}
                  type="text"
                  variant="outlined"
                  className={classes.input}
                  disabled={disabled}
                />
              </div>
            </div>
            {model.configuration.showAttachments && (
              <div>
                <FormSectionDivider title="Adjuntos" />
                <div className={classes.rowContainer}>
                  <DropZone
                    files={files}
                    setFiles={setFiles}
                    configuration={model.configuration}
                    error={attachmentError}
                    setError={setAttachmentError}
                    disabled={disabled}
                    selectedDocument={selectedDocument}
                    accept={model.configuration.availableExtensions}
                  />
                </div>
              </div>
            )}
          </div>
          <div className={classes.actionsContainer}>
            <FormikActions handleCancel={handleClose} disabled={disabled} />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default Fpt;
