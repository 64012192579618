import { List, ListItem, ListItemIcon } from "@material-ui/core";
import Badge from "@material-ui/core/Badge";
import { makeStyles } from "@material-ui/core/styles";
import { NewReleases } from "@material-ui/icons";
import React from "react";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  nested: {
    borderRadius: "7px",
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(2),
    },
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },
  list: {
    backgroundColor: (props) =>
      props.selected
        ? theme.palette.primary.main
        : theme.palette.primary.contrastText,
    color: theme.palette.primary.contrastText,
    borderRadius: "7px",
    marginTop: 5,
    marginLeft: (props) => (props.open ? 25 : 0),
    "&:hover": {
      // width: props => (!props.open) && '100%',
    },
  },
  listIcon: {
    color: (props) =>
      props.selected
        ? theme.palette.primary.contrastText
        : theme.palette.primary.backgroundListItem,
  },
  rightIcon: {
    color: (props) =>
      props.selected
        ? theme.palette.primary.contrastText
        : theme.palette.primary.main,
    marginLeft: 30,
  },
  text: {
    color: (props) =>
      props.selected
        ? theme.palette.primary.contrastText
        : theme.palette.primary.backgroundListItem,
    fontFamily: "Roboto, sans-serif",
    margin: 0,
  },
}));

const ListSubItemComponent = ({
  path,
  selected,
  title,
  title2,
  icon: Icon,
  open,
  id,
  isNew,
  notification,
}) => {
  const classes = useStyles({ selected, open });

  return (
    <List component="div" disablePadding className={classes.list}>
      <ListItem
        button
        className={classes.nested}
        component={Link}
        selected={selected}
        to={path}
        id={id}>
        <ListItemIcon className={classes.listIcon}>{Icon}</ListItemIcon>
        {open && (
          <div style={{ lineHeight: 1.3 }}>
            <p className={classes.text}>{title}</p>
            <p className={classes.text}>{title2}</p>
          </div>
        )}
        {isNew && (
          <ListItemIcon className={classes.rightIcon}>
            <NewReleases />
          </ListItemIcon>
        )}
        {notification > 0 && (
          <ListItemIcon className={classes.rightIcon}>
            <Badge badgeContent={notification} color="primary" />
          </ListItemIcon>
        )}
      </ListItem>
    </List>
  );
};

export default ListSubItemComponent;
