import React, { useEffect } from "react";
import FormikAutocomplete from "../../../commons/formik/formikAutocomplete";
import FormikCheckbox from "../../../commons/formik/formikCheckbox";
import { useStyles } from "../formStyle";

const SpecialtyForm = ({
  disabled,
  fields,
  setFieldValue,
  especialidadesInforme,
  values,
  isView,
  especialidadesProfesional,
}) => {
  const classes = useStyles();

  useEffect(() => {
    if (
      especialidadesInforme.length !== 0 &&
      especialidadesProfesional.length !== 0
    ) {
      let especialidad;
      setFieldValue("especialidadInforme", values.especialidadProfesional);
      // si is View cargar con la especialidad del informe, sino cargarla con la del profesional

      if (isView) {
        if (values.especialidadInforme) {
          setFieldValue("especialidadInforme", values.especialidadInforme);
          //cargo con la especialidad del informe que me viene en el getForm
          especialidad = especialidadesProfesional.options.find(
            (e) =>
              e.id.toUpperCase() === values.especialidadInforme.toUpperCase(),
          );
        } else {
          setFieldValue("especialidadInforme", "");
        }
      } else {
        if (!fields.especialidadInforme.options) {
          setFieldValue("especialidadInforme", values.especialidadProfesional);
          especialidad = especialidadesProfesional.options.find(
            (e) =>
              e.id.toUpperCase() ===
              values.especialidadProfesional.toUpperCase(),
          );
        } else {
          setFieldValue(
            "especialidadInforme",
            fields.especialidadInforme.options,
          );
          especialidad = especialidadesProfesional.options.find(
            (e) =>
              e.id.toUpperCase() ===
              fields.especialidadInforme.options.toUpperCase(),
          );
        }
      }

      especialidad &&
        setFieldValue("informacionSensible", especialidad.informacionSensible);
    }
  }, [especialidadesInforme]);

  return (
    <div className={classes.rowContainer}>
      <div className={classes.leftColumnContainer}>
        <FormikAutocomplete
          name="especialidadInforme"
          label={`Especialidad del informe ${
            fields.especialidadInforme.required ? "*" : ""
          }`}
          callBack={(value) => {
            if (value) {
              setFieldValue(
                "informacionSensible",
                value.informacionSensible || false,
              );
              return;
            }
            setFieldValue("informacionSensible", false);
          }}
          variant="outlined"
          options={especialidadesInforme}
          className={classes.input}
          disabled={disabled}
        />
      </div>
      <div className={classes.rightColumnContainerCheckBoxStart}>
        <FormikCheckbox
          name="informacionSensible"
          label="Información Sensible"
          labelPlacement="end"
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default SpecialtyForm;
