import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import promise from "redux-promise-middleware";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import reducer from "./reducers";

import { reduxErrorMiddleware } from "./middlewares/reduxErrorMiddleware";
import { reduxUnauthorizedMiddleware } from "./middlewares/reduxUnauthorizedMiddleware";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth", "userPreferences"],
};

const middleware = [
  thunk,
  reduxUnauthorizedMiddleware,
  reduxErrorMiddleware,
  promise,
];

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = createStore(
  persistedReducer,
  applyMiddleware(...middleware),
);

export const persistor = persistStore(store);
