import axios from "axios";

export const GET_ART = "GET_ART";
export const GET_ARTS_NOT_ADHERED = "GET_ARTS_NOT_ADHERED";
export const ADHERE = "ADHERE";
export const REQUEST_ADHESION = "REQUEST_ADHESION";

export const getArt = () => (dispatch, getState) => {
  const { selectedProvider } = getState().providers;

  if (selectedProvider) {
    dispatch({
      type: GET_ART,
      payload: axios
        .get(`provider/${selectedProvider}/arts`)
        .then((response) => ({ data: response.data }))
        .catch((error) => Promise.reject(error)),
    });
  }
};

export const getArtsNotAdhered = () => (dispatch, getState) => {
  const { selectedProvider } = getState().providers;
  if (selectedProvider) {
    dispatch({
      type: GET_ARTS_NOT_ADHERED,
      payload: axios
        .get(`provider/${selectedProvider}/wris/notadhered`)
        .then((response) => ({ data: response.data }))
        .catch((error) => Promise.reject(error)),
    });
  }
};

export const requestAdhesion = (art) => (dispatch, getState) => {
  const { selectedProvider } = getState().providers;
  return dispatch({
    type: REQUEST_ADHESION,
    payload: axios
      .put(`provider/${selectedProvider}/wris/adhere`, {
        ArtId: art.id,
        IdPrestadorArt: 8,
      })
      .then((response) => ({ data: response.data, art }))
      .catch((error) => {
        Promise.reject(error);
        return error;
      }),
  });
};

export const getArtsNotAdheredByProviderId = (providerId) => (dispatch) => {
  dispatch({
    type: GET_ARTS_NOT_ADHERED,
    payload: axios
      .get(`provider/${providerId}/wris/notadhered`)
      .then((response) => ({ data: response.data }))
      .catch((error) => Promise.reject(error)),
  });
};
