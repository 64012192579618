import * as Yup from "yup";
import moment from "moment";

export const iamiValidation = (fechaPrimeraCuracionMinDate) =>
  Yup.object({
    fechaHoraAccidente: Yup.date()
      .nullable()
      .typeError("Fecha inválida")
      .max(
        new Date(Date.now()),
        "La fecha de accidente no puede ser mayor a la fecha actual",
      ),
    fechaHoraAbondonoTrabajo: Yup.date()
      .nullable()
      .max(
        new Date(Date.now()),
        "La fecha de abandono de trabajo no puede ser mayor a la fecha actual",
      )
      .typeError("Fecha inválida")
      .test({
        name: "minFechaHoraAccidente",
        message:
          "La fecha en que abandonó el trabajo debe ser mayor a la fecha de accidente",
        test: function (value) {
          return this.parent.fechaHoraAccidente && value
            ? value >
                new Date(this.parent.fechaHoraAccidente).setHours(0, 0, 0, 0)
            : true;
        },
      }),
    fechaPrimeraCuracion: Yup.date()
      .nullable()
      .max(
        new Date(Date.now()),
        "La fecha de primera curación no puede ser mayor a la fecha actual",
      )
      .typeError("Fecha inválida")
      .test({
        name: "minFechaHoraAccidente",
        message:
          "La fecha de primera curación debe ser mayor o igual a la fecha de accidente",
        test: function (value) {
          return this.parent.fechaHoraAccidente && value
            ? value >=
                new Date(this.parent.fechaHoraAccidente).setHours(0, 0, 0, 0)
            : true;
        },
      })
      .test({
        name: "minFechaPrimeraCuracion",
        message: `La fecha de primera curación debe ser mayor a ${moment(
          fechaPrimeraCuracionMinDate,
        ).format("DD/MM/YYYY")}`,
        test: function (value) {
          return fechaPrimeraCuracionMinDate
            ? value > new Date(fechaPrimeraCuracionMinDate)
            : true;
        },
      }),
    fechaHoraProximaConsulta: Yup.date()
      .nullable()
      .min(
        new Date(Date.now()),
        "La fecha de próxima consulta no puede ser menor a la fecha actual",
      )
      .typeError("Fecha inválida")
      .test({
        name: "minFechaHoraAccidente",
        message:
          "La fecha de próxima consulta debe ser mayor a la fecha de accidente",
        test: function (value) {
          return this.parent.fechaHoraAccidente && value
            ? value > this.parent.fechaHoraAccidente
            : true;
        },
      })
      .test({
        name: "minFechaPrimeraCuracion",
        message:
          "La fecha de próxima consulta debe ser mayor a la fecha de primera curación",
        test: function (value) {
          return this.parent.fechaPrimeraCuracion && value
            ? new Date(value).setHours(0, 0, 0, 0) >
                this.parent.fechaPrimeraCuracion
            : true;
        },
      }),
    fechaAtencionReagravacion: Yup.date()
      .nullable()
      .typeError("Fecha inválida")
      .test({
        name: "required",
        message: "Campo obligatorio",
        test: function (value) {
          return this.parent.reagravacion ? value : true;
        },
      }),
    fechaReagravacion: Yup.date()
      .nullable()
      .typeError("Fecha inválida")
      .test({
        name: "required",
        message: "Campo obligatorio",
        test: function (value) {
          return this.parent.reagravacion ? value : true;
        },
      })
      .test({
        name: "maxFechaAtencionReagravacion",
        message:
          "La fecha de reagravación debe ser menor o igual a la fecha de atención de reagravación",
        test: function (value) {
          return this.parent.fechaAtencionReagravacion &&
            this.parent.reagravacion
            ? value <= this.parent.fechaAtencionReagravacion
            : true;
        },
      }),
    tipoReingreso: Yup.string()
      .nullable()
      .test({
        name: "required",
        message: "Campo obligatorio",
        test: function (value) {
          return this.parent.reagravacion ? value : true;
        },
      }),
    comoOcurrioAccidente: Yup.string().max(
      1000,
      "El campo no puede superar los 1000 caracteres",
    ),
    centroAsistencial: Yup.string().max(
      30,
      "El campo no puede superar los 30 caracteres",
    ),
    diagnostico: Yup.string().max(
      1200,
      "El campo no puede superar los 1200 caracteres",
    ),
    tratamiento: Yup.string().max(
      1500,
      "El campo no puede superar los 1500 caracteres",
    ),
    profesionalApellidoNombre: Yup.string()
      .nullable()
      .max(40, "El campo no puede superar los 40 caracteres"),
    profesionalMnMp: Yup.string().max(
      15,
      "El campo no puede superar los 15 caracteres",
    ),
  });

export const ietValidation = (fechaAtencionMinDate) =>
  Yup.object({
    fechaAtencion: Yup.date()
      .nullable()
      .typeError("Fecha inválida")
      .max(
        new Date(Date.now()),
        "La fecha hora de atención no puede ser mayor a la fecha actual",
      )
      .test({
        name: "maxFechaHoraNuevoControl",
        message:
          "La fecha de atención debe ser menor a la fecha de próxima consulta",
        test: function (value) {
          return this.parent.fechaHoraNuevoControl
            ? value < this.parent.fechaHoraNuevoControl
            : true;
        },
      })
      .test({
        name: "minFechaAtencion",
        message: `La fecha de atención debe ser mayor a la fecha de primera curación: ${moment(
          fechaAtencionMinDate,
        ).format("DD/MM/YYYY HH:mm")}`,
        test: function (value) {
          return fechaAtencionMinDate
            ? value > new Date(fechaAtencionMinDate)
            : true;
        },
      }),
    fechaHoraNuevoControl: Yup.date()
      .nullable()
      .typeError("Fecha inválida")
      .min(
        new Date(Date.now()),
        "La fecha hora de próxima consulta no puede ser menor a la fecha actual",
      ),
    diagnostico: Yup.string().max(
      1200,
      "El campo no puede superar los 1200 caracteres",
    ),
    observaciones: Yup.string()
      .nullable()
      .max(6000, "El campo no puede superar los 6000 caracteres"),
    profesionalApellidoNombre: Yup.string()
      .nullable()
      .max(40, "El campo no puede superar los 40 caracteres"),
    profesionalMnMp: Yup.string().max(
      15,
      "El campo no puede superar los 15 caracteres",
    ),
  });

export const amValidation = (
  fechaAltaMinDate,
  configuration,
  diasReinicioLaboral,
) =>
  Yup.object({
    fechaAlta: Yup.date()
      .nullable()
      .typeError("Fecha inválida")
      .test({
        name: "minFechaAlta",
        message: `La fecha de alta debe ser mayor o igual a la fecha: ${moment(
          fechaAltaMinDate,
        ).format("DD/MM/YYYY")}`,
        test: function (value) {
          return fechaAltaMinDate ? value >= new Date(fechaAltaMinDate) : true;
        },
      })
      .test({
        name: "maxFechaAlta",
        message: "La fecha de alta debe ser menor o igual a la fecha actual",
        test: function (value) {
          return configuration.fechaAltaMax ? value <= new Date() : true;
        },
      }),
    fechaReinicioLaboral: Yup.date()
      .nullable()
      .typeError("Fecha inválida")
      .test({
        name: "minFechaReinicioLaboral",
        message:
          "La fecha de reinicio laboral debe ser mayor a la fecha de alta",
        test: function (value) {
          return value && this.parent.fechaAlta
            ? value > this.parent.fechaAlta
            : true;
        },
      })
      .test({
        name: "minFechaReinicioLaboralDefault",
        message: `La fecha de reinicio laboral debe ser mayor a la fecha de alta más ${diasReinicioLaboral} día${
          diasReinicioLaboral === 1 ? "" : "s"
        }`,
        test: function (value) {
          const fechaMin = moment(this.parent.fechaAlta).add(
            diasReinicioLaboral,
            "days",
          );
          return value &&
            configuration.fechaReinicioLaboralMin &&
            this.parent.fechaAlta
            ? moment(value).diff(fechaMin, "days") >= 0
            : true;
        },
      }),
    firmaConforme: Yup.string()
      .nullable()
      .test({
        name: "firmaConforme",
        message: "Campo obligatorio",
        test: function (value) {
          return (
            (this.parent.marcaFirmado === "Si" && value !== null) ||
            (this.parent.marcaFirmado === "No" && value === null)
          );
        },
      }),
    diagnostico: Yup.string().max(
      1200,
      "El campo no puede superar los 1200 caracteres",
    ),
    observaciones: Yup.string()
      .nullable()
      .max(1000, "El campo no puede superar los 1000 caracteres"),
    profesionalApellidoNombre: Yup.string()
      .nullable()
      .max(40, "El campo no puede superar los 40 caracteres"),
    profesionalMnMp: Yup.string().max(
      15,
      "El campo no puede superar los 15 caracteres",
    ),
  });

export const fpaValidation = (fechaHoraPedidoMinDate) =>
  Yup.object({
    fechaHoraPedido: Yup.date()
      .nullable()
      .typeError("Fecha inválida")
      .test({
        name: "minFechaHoraPedido",
        message: `La fecha debe ser mayor o igual a la fecha: ${moment(
          fechaHoraPedidoMinDate,
        ).format("DD/MM/YYYY")}`,
        test: function (value) {
          return fechaHoraPedidoMinDate && value
            ? value >= new Date(fechaHoraPedidoMinDate)
            : true;
        },
      }),
    diagnostico: Yup.string().max(
      1200,
      "El campo no puede superar los 1200 caracteres",
    ),
    detalleAutorizacion: Yup.string()
      .nullable()
      .max(1000, "El campo no puede superar los 1000 caracteres"),
    planTerapeutico: Yup.string()
      .nullable()
      .max(500, "El campo no puede superar los 500 caracteres"),
    profesionalApellidoNombre: Yup.string()
      .nullable()
      .max(40, "El campo no puede superar los 40 caracteres"),
    profesionalMnMp: Yup.string().max(
      15,
      "El campo no puede superar los 15 caracteres",
    ),
  });

export const fptValidation = (fechaHoraSolicitudMinDate) =>
  Yup.object({
    fechaHoraSolicitud: Yup.date()
      .nullable()
      .typeError("Fecha inválida")
      .test({
        name: "minFechaHoraSolicitud",
        message: `La fecha debe ser mayor o igual a la fecha: ${moment(
          fechaHoraSolicitudMinDate,
        ).format("DD/MM/YYYY")}`,
        test: function (value) {
          return fechaHoraSolicitudMinDate && value
            ? value >= new Date(fechaHoraSolicitudMinDate)
            : true;
        },
      }),
    fechaHoraTurno: Yup.date()
      .nullable()
      .typeError("Fecha inválida")
      .test({
        name: "minfechaHoraTurno",
        message: "La fecha debe ser mayor a la fecha de solicitud",
        test: function (value) {
          return this.parent.fechaHoraSolicitud
            ? value > this.parent.fechaHoraSolicitud
            : true;
        },
      }),
    calleDesde: Yup.string()
      .max(50, "El campo no puede superar los 50 caracteres")
      .test({
        name: "sameAscalleHasta",
        message:
          "La calle y número Desde no pueden coincidir con la calle y número Hasta",
        test: function (value) {
          return this.parent.calleHasta && this.parent.numeroHasta
            ? value !== this.parent.calleHasta ||
                this.parent.numeroDesde !== this.parent.numeroHasta
            : true;
        },
      }),
    pisoDesde: Yup.string().max(
      5,
      "El campo no puede superar los 5 caracteres",
    ),
    departamentoDesde: Yup.string().max(
      5,
      "El campo no puede superar los 5 caracteres",
    ),
    entreCalleDesde: Yup.string().max(
      50,
      "El campo no puede superar los 50 caracteres",
    ),
    entreCalleDesde1: Yup.string().max(
      50,
      "El campo no puede superar los 50 caracteres",
    ),
    localidadDesde: Yup.string().max(
      50,
      "El campo no puede superar los 50 caracteres",
    ),
    provinciaDesde: Yup.string().max(
      20,
      "El campo no puede superar los 20 caracteres",
    ),
    codigoPostalDesde: Yup.number()
      .required("Campo obligatorio")
      .test({
        name: "codigoPostalDesde",
        message: "El código postal no puede ser 0",
        test: function (value) {
          return value ? value !== 0 : false;
        },
      }),
    telefonosDesde: Yup.string().max(
      20,
      "El campo no puede superar los 20 caracteres",
    ),
    calleHasta: Yup.string()
      .max(50, "El campo no puede superar los 50 caracteres")
      .test({
        name: "sameAscalleDesde",
        message:
          "La calle y número Hasta no pueden coincidir con la calle y número Desde",
        test: function (value) {
          return this.parent.calleDesde && this.parent.numeroDesde
            ? value !== this.parent.calleDesde ||
                this.parent.numeroHasta !== this.parent.numeroDesde
            : true;
        },
      }),
    pisoHasta: Yup.string().max(
      5,
      "El campo no puede superar los 5 caracteres",
    ),
    departamentoHasta: Yup.string().max(
      5,
      "El campo no puede superar los 5 caracteres",
    ),
    entreCalleHasta: Yup.string().max(
      50,
      "El campo no puede superar los 50 caracteres",
    ),
    entreCalleHasta1: Yup.string().max(
      50,
      "El campo no puede superar los 50 caracteres",
    ),
    localidadHasta: Yup.string().max(
      50,
      "El campo no puede superar los 50 caracteres",
    ),
    provinciaHasta: Yup.string().max(
      20,
      "El campo no puede superar los 20 caracteres",
    ),
    codigoPostalHasta: Yup.number()
      .required("Campo obligatorio")
      .test({
        name: "codigoPostalHasta",
        message: "El código postal no puede ser 0",
        test: function (value) {
          return value ? value !== 0 : false;
        },
      }),
    telefonosHasta: Yup.string().max(
      20,
      "El campo no puede superar los 20 caracteres",
    ),
    observaciones: Yup.string().max(
      500,
      "El campo no puede superar los 500 caracteres",
    ),
  });

export const siniestroValidation = (fromFacade) =>
  Yup.object({
    fechaHoraAccidente: Yup.date()
      .nullable()
      .typeError("Fecha inválida")
      .max(
        new Date(Date.now()),
        "La fecha hora de accidente no puede ser mayor a la fecha actual",
      ),
    nroSiniestroArt: Yup.string().max(
      15,
      "El campo no puede superar los 15 caracteres",
    ),
    numeroDocumentoTrabajador: Yup.number()
      .integer("Número de documento inválido")
      .max(99999999, "El campo no puede superar los 8 dígitos"),
    apellidoTrabajador: Yup.string().max(
      40,
      "El campo no puede superar los 40 caracteres",
    ),
    nombreTrabajador: Yup.string().max(
      40,
      "El campo no puede superar los 40 caracteres",
    ),
    fechaNacimientoTrabajador: Yup.date()
      .nullable()
      .typeError("Fecha inválida")
      .max(
        new Date(Date.now()),
        "La fecha de nacimiento no puede ser mayor a la fecha actual",
      ),
    cuilCuitTrabajador: Yup.string()
      .max(13, "El campo no puede superar los 13 caracteres")
      .test({
        name: "cuilFormat",
        message: "Cuil inválido",
        test: function (value) {
          return !value || fromFacade
            ? true
            : value.match(/^([0-9]{2}-[0-9]{8}-[0-9]{1})$/);
        },
      }),
    calleTrabajador: Yup.string().max(
      50,
      "El campo no puede superar los 50 caracteres",
    ),
    pisoTrabajador: Yup.string().max(
      5,
      "El campo no puede superar los 5 caracteres",
    ),
    departamentoTrabajador: Yup.string().max(
      5,
      "El campo no puede superar los 5 caracteres",
    ),
    localidadTrabajador: Yup.string().max(
      50,
      "El campo no puede superar los 50 caracteres",
    ),
    provinciaTrabajador: Yup.string().max(
      20,
      "El campo no puede superar los 20 caracteres",
    ),
    telefonosTrabajador: Yup.string().max(
      20,
      "El campo no puede superar los 20 caracteres",
    ),
    areaTelefonoCelularTrabajador: Yup.string().max(
      5,
      "El campo no puede superar los 5 caracteres",
    ),
    telefonoCelularTrabajador: Yup.string().max(
      30,
      "El campo no puede superar los 30 caracteres",
    ),
    cuitEmpleador: Yup.string()
      .max(13, "El campo no puede superar los 13 caracteres")
      .test({
        name: "cuitFormat",
        message: "Cuit inválido",
        test: function (value) {
          return !value || fromFacade
            ? true
            : value.match(/^([0-9]{2}-[0-9]{8}-[0-9]{1})$/);
        },
      }),
    razonSocialEmpleador: Yup.string().max(
      50,
      "El campo no puede superar los 50 caracteres",
    ),
    calleEmpleador: Yup.string().max(
      50,
      "El campo no puede superar los 50 caracteres",
    ),
    pisoEmpleador: Yup.string().max(
      5,
      "El campo no puede superar los 5 caracteres",
    ),
    departamentoEmpleador: Yup.string().max(
      5,
      "El campo no puede superar los 5 caracteres",
    ),
    localidadEmpleador: Yup.string().max(
      50,
      "El campo no puede superar los 50 caracteres",
    ),
    provinciaEmpleador: Yup.string().max(
      20,
      "El campo no puede superar los 20 caracteres",
    ),
    telefonosEmpleador: Yup.string().max(
      20,
      "El campo no puede superar los 20 caracteres",
    ),
  });

export const idrValidation = () =>
  Yup.object({
    detalle: Yup.string()
      .nullable()
      .max(6000, "El campo no puede superar los 6000 caracteres"),
    profesionalApellidoNombre: Yup.string()
      .nullable()
      .max(40, "El campo no puede superar los 40 caracteres"),
    profesionalMnMp: Yup.string().max(
      15,
      "El campo no puede superar los 15 caracteres",
    ),
  });

export const validateRequiredFields = (values, model) => {
  const errors = {};
  const errorMessage = "Campo obligatorio";

  for (var field in model) {
    if (model[field].required && !values[field] && values[field] !== 0) {
      errors[field] = errorMessage;
    }
  }

  return errors;
};
