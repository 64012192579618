import {
  Button,
  CardContent,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import { Form, Formik } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { resetPassword } from "../../../actions/password";
import Logo from "../../../assets/images/logo_medium.png";
import snackbarUtils from "../../../utils/snackbarUtils";
import FormikTextField from "../../commons/formik/formikTextField";
import LoginFooter from "../login/loginFooter";

const useStyles = makeStyles((theme) => ({
  text: {
    color: "#303030",
    fontFamily: "Roboto, sans-serif",
    fontWeight: "bold",
    fontSize: "medium",
  },
  gridContainer: {
    flex: 1,
    alignItems: "center",
    backgroundImage: "linear-gradient(120deg, #96d43a, #539aff)",
  },
  gridRowItem: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  gridItemAligned: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: 10,
  },
  logo: {
    paddingBottom: "60px",
  },
  loginCard: {
    minWidth: "450px",
    backgroundColor: "white",
    boxShadow: "0px 0px 5px 0px grey",
    borderRadius: "10px",
    padding: "30px 25px 10px 25px",
  },
  form: {
    width: "100%",
  },
  button: {
    marginTop: 20,
    backgroundColor: `${theme.palette.primary.gradientLogin2}`,
  },
}));

const validationSchema = yup.object({
  email: yup
    .string()
    .email("Dirección de correo inválida")
    .required("Campo obligatorio"),
});

const initialValues = {
  email: "",
};

const PasswordRecoveryContainer = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loading = useSelector(
    (state) => state.password.resetPassword.isFetching,
  );

  const handleSubmitEmail = (values) => {
    dispatch(resetPassword(values.email))
      .then((datos) => {
        snackbarUtils.success(
          "Le enviaremos un e-mail a la brevedad.\nRecuerde verificar el correo no deseado o spam.",
        );
        setTimeout(() => {
          navigate("/account/login");
        }, 4000);
      })
      .catch((error) => {});
  };

  return (
    <Grid container justify="center" className={classes.gridContainer}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmitEmail}>
        <Paper className={classes.loginCard}>
          <Form className={classes.form}>
            <CardContent>
              <Grid
                container
                justify="center"
                alignItems="center"
                direction="column">
                <img src={Logo} className={classes.logo} alt="" />
              </Grid>
              <Typography className={classes.text}>
                Recuperar contraseña
              </Typography>

              <Grid container direction={"row"} spacing={2} nowrap="true">
                <Grid item xs={12} className={classes.gridRowItem}>
                  <EmailOutlinedIcon className={classes.gridItemAligned} />
                  <FormikTextField
                    variant="outlined"
                    className={classes.gridItemAligned}
                    margin="normal"
                    fullWidth
                    label="Correo electrónico"
                    name="email"
                    autoFocus
                    type="text"
                  />
                </Grid>
              </Grid>

              <Button
                color="primary"
                variant="contained"
                className={classes.button}
                fullWidth
                type="submit"
                disabled={loading}>
                {loading ? (
                  <CircularProgress size={24} thickness={4} color="secondary" />
                ) : (
                  "Enviar"
                )}
              </Button>
            </CardContent>
          </Form>
          <LoginFooter loginAccount></LoginFooter>
        </Paper>
      </Formik>
    </Grid>
  );
};

export default PasswordRecoveryContainer;
