import axios from "axios";

export const USER_LOGIN = "USER_LOGIN";
export const SIGNOUT_REQUEST = "SIGNOUT_REQUEST";
export const UNAUTHORIZED_REQUEST = "UNAUTHORIZED_REQUEST";

export const loginUser = (credentials, userName) => ({
  type: USER_LOGIN,
  payload: axios
    .post("account/login", credentials)
    .then((response) => {
      const { token } = response.data;
      const { roles } = response.data;

      axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
      return {
        token,
        roles,
        userName,
      };
    })
    .catch((error) => Promise.reject(error)),
});

export const signoutUser = () => (dispatch) => {
  dispatch({
    type: SIGNOUT_REQUEST,
  });
};

export const impersonateUser = (user) => ({
  type: USER_LOGIN,
  payload: axios
    .post("account/impersonate", user)
    .then((response) => {
      const { token } = response.data;
      const { roles } = response.data;

      axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
      return {
        token,
        roles,
        userName: user.userName,
      };
    })
    .catch((error) => Promise.reject(error)),
});

export const unauthorizedRequest = () => ({
  type: UNAUTHORIZED_REQUEST,
});
