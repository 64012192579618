import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDocuments } from "../../../../actions/accidentDashboard";
import { clearIdTurnos } from "../../../../actions/appointments";
import {
  correctConnectivityErrorAction,
  getAllConnectivityErrors,
  getConnectivityErrors,
} from "../../../../actions/connectivityResponse";
import { postForm } from "../../../../actions/forms";
import { convertDateTimeToNet } from "../../../../utils/datetimeUtils";
import CircularLoading from "../../../commons/loadingData/circularLoading";
import Fpt from "./fpt";

const initialFptModel = {
  fechaHoraSolicitud: null,
  fechaHoraTurno: null,
  tipoTransporteNombre: "",
  tipoUrgenciaNombre: 1,
  solicitadoPor: null,
  direccionHabitualDesde: null,
  calleDesde: "",
  numeroDesde: "",
  pisoDesde: "",
  departamentoDesde: "",
  entreCalleDesde: "",
  entreCalleDesde1: "",
  localidadDesde: "",
  provinciaDesde: "",
  codigoPostalDesde: "",
  telefonosDesde: "",
  direccionHabitualHasta: null,
  calleHasta: "",
  numeroHasta: "",
  pisoHasta: "",
  departamentoHasta: "",
  entreCalleHasta: "",
  entreCalleHasta1: "",
  localidadHasta: "",
  provinciaHasta: "",
  codigoPostalHasta: "",
  telefonosHasta: "",
  observaciones: "",
  esperaRetorno: null,
  tipoDireccionDesde: null,
  tipoDireccionHasta: null,
};

const FptContainer = ({
  handleClose,
  handleOpenConfirmDialog,
  isView,
  setLoaded,
  idArt,
  siniestroProps,
  selectedDocument,
  fromErroresConectividad,
  fromErrorDialog,
}) => {
  const dispatch = useDispatch();

  const accident = useSelector((state) => state.accidentDashboard.accident);
  const saveForm = useSelector((state) => state.forms.saveForm);
  const postAppointment = useSelector(
    (state) => state.appointments.postAppointment,
  );
  const postSession = useSelector(
    (state) => state.appointments.postSession.data,
  );
  const appointmentState = useSelector(
    (state) => state.appointments.postSession.isFetching,
  );
  const model = useSelector((state) => state.forms.model);
  const form = useSelector((state) => state.forms.form);
  const fpt = useSelector((state) => state.forms.fpt);
  const time = useSelector(
    (state) => state.connectivityResponse.connectivityErrors.time,
  );
  const [files, setFiles] = useState([]);
  const [fptModel, setFptModel] = useState(null);
  const [siniestroState, setSiniestroState] = useState(null);
  const [dateWSplit, setDateWSplit] = useState(null);
  const [idArtProps, setIdArtProps] = useState(null);

  const isLoading =
    model.isFetching ||
    saveForm.isFetching ||
    form.isFetching ||
    (!isView && !fptModel) ||
    (fpt.isOpen && dateWSplit === null) ||
    (fpt.isOpen && fpt.isOutsideAccident && appointmentState);

  useEffect(() => {
    if (isView && !form.isFetching) {
      setFiles(form.data.form.adjuntos);
    }
  }, [form.isFetching]);

  useEffect(() => {
    if (!isLoading && !!setLoaded) {
      setLoaded(true);
    }
  }, [model.isFetching, saveForm.isFetching, form.isFetching]);

  useEffect(() => {
    if (idArt) {
      setIdArtProps(idArt);
    } else if (selectedDocument) {
      setIdArtProps(accident.mlCloudCoreArtId);
    }
    if (siniestroProps) {
      setSiniestroState(siniestroProps);
    }
  }, [idArt, siniestroProps]);

  useEffect(() => {
    if (fpt.isOpen) {
      if (!Array.isArray(fpt.data)) {
        var fechaHora =
          fpt.data.fechaHoraNuevoControl ||
          fpt.data.fechaHora ||
          fpt.data.fechaHoraProximaConsulta;
        setDateWSplit(moment(fechaHora).format("DD/MM/YYYY HH:mm"));
      } else {
        setDateWSplit({
          primerTurno: moment(fpt.data[0].fechaHora).format("DD/MM/YYYY HH:mm"),
          ultimoTurno: moment(fpt.data[fpt.data.length - 1].fechaHora).format(
            "DD/MM/YYYY HH:mm",
          ),
        });
      }
    }
  }, [fpt.isOpen]);

  useEffect(() => {
    if (!model.isFetching && !isView) {
      if (!fpt.isOpen) {
        setFptModel({
          ...initialFptModel,
          fechaHoraSolicitud: new Date(),
        });
      } else if (dateWSplit !== null) {
        if (!dateWSplit.primerTurno) {
          if (!fpt.data.especialidadNombre) {
            var especialidad = fpt.data.appointment.especialidadNombre;
          } else {
            var especialidad = fpt.data.especialidadNombre;
          }
          setFptModel({
            ...initialFptModel,
            solicitadoPor: fpt.data.profesionalApellidoNombre,
            fechaHoraSolicitud: new Date(),
            observaciones:
              "Fechas turnos: " +
              dateWSplit +
              " | " +
              "Profesional: " +
              fpt.data.profesionalApellidoNombre +
              " | " +
              "Especialidad: " +
              especialidad,

            fechaHoraTurno:
              fpt.data.fechaHoraNuevoControl ||
              fpt.data.fechaHoraProximaConsulta ||
              fpt.data.fechaHora ||
              fpt.data[0].fechaHora,
          });
        } else {
          setFptModel({
            ...initialFptModel,
            solicitadoPor: fpt.data[0].profesionalApellidoNombre,
            fechaHoraSolicitud: new Date(),
            observaciones:
              "Fecha turnos: " +
                dateWSplit.primerTurno +
                " - " +
                dateWSplit.ultimoTurno +
                " | " +
                "Profesional: " +
                fpt.data[0].profesionalApellidoNombre +
                " | " +
                "Especialidad: " +
                fpt.data[0].especialidadNombre ||
              fpt.data.appointment.especialidadNombre ||
              fpt.data.especialidadNombre,

            fechaHoraTurno: moment(dateWSplit.primerTurno, "DD/MM/YYYY HH:mm"),
          });
        }
      }
    }
  }, [model.isFetching, dateWSplit]);

  const handleSubmit = (form, idArt) => {
    const filesAux = [];
    files.forEach((e) =>
      filesAux.push({ ...e, nombre: e.filename || e.nombre, base64: e.value }),
    );
    var model = {
      ...form,
      numeroSiniestroPrestador: accident.idSiniestro || siniestroState,
      adjuntos: filesAux,
      fechaHoraSolicitud: convertDateTimeToNet(form.fechaHoraSolicitud),
      fechaHoraTurno: convertDateTimeToNet(form.fechaHoraTurno),
    };
    if (fromErroresConectividad) {
      model.numeroSiniestroPrestador = selectedDocument.siniestro;
    }
    if (dateWSplit) {
      //creo un array vacio para que el back reciba un array con un solo id y no el id directo, asi funciona el codigo del back sin modificaciones

      var arrayIdTurnosAux = [];
      if (postAppointment.data.idTurnoSiniestro) {
        arrayIdTurnosAux.push(postAppointment.data.idTurnoSiniestro);
      } else if (saveForm.data.idTurnosArray) {
        arrayIdTurnosAux = saveForm.data.idTurnosArray;
      } else if (postSession.turnosArray) {
        arrayIdTurnosAux = postSession.turnosArray;
      } else if (fpt.data.appointment) {
        arrayIdTurnosAux.push(fpt.data.appointment.idTurnoSiniestro);
      }
      model = {
        ...model,
        idTurnos: arrayIdTurnosAux,
      };
      //borro la data del redux que me trae el post segun sea session o unico turno
      dispatch(clearIdTurnos());
    }

    dispatch(postForm("fpt", model, idArt))
      .then(() => {
        if (fromErrorDialog) {
          dispatch(
            correctConnectivityErrorAction(
              selectedDocument.idRespError,
              accident.mlCloudCoreArtId,
            ),
          ).then((res) => {
            dispatch(
              getDocuments(accident.idSiniestro, accident.mlCloudCoreArtId),
            );
            dispatch(
              getConnectivityErrors(
                selectedDocument.nroTransaccion,
                accident.mlCloudCoreArtId,
              ),
            ).then((res) => handleClose(true, false));
          });
        } else {
          handleClose(true, false);
        }
        if (fromErroresConectividad) {
          dispatch(
            correctConnectivityErrorAction(
              selectedDocument.idRespError,
              accident.mlCloudCoreArtId,
            ),
          ).then((res) => {
            dispatch(getAllConnectivityErrors(time));
          });
        }
      })
      .catch(() => handleClose(true, true));
  };

  return (
    <div style={{ width: "100%" }}>
      {isLoading ? (
        <div style={{ marginTop: 100 }}>
          <CircularLoading />
        </div>
      ) : isView ? (
        <Fpt
          handleClose={handleClose}
          files={files}
          setFiles={setFiles}
          handleSubmit={handleSubmit}
          model={form.data.model}
          fptModel={form.data.form}
          roles={form.data.roles}
          selectedDocument={selectedDocument}
          idArt={idArtProps}
          fromErrorDialog={fromErrorDialog}
          fromErroresConectividad={fromErroresConectividad}
          isView={isView}
        />
      ) : (
        <Fpt
          handleClose={handleOpenConfirmDialog}
          files={files}
          setFiles={setFiles}
          handleSubmit={handleSubmit}
          model={model.data}
          fptModel={fptModel}
          idArt={idArtProps}
          isView={isView}
          disableDateUpdate={fpt.isOpen}
        />
      )}
    </div>
  );
};

export default FptContainer;
