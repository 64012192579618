import { unauthorizedRequest } from "../actions/login";

export const reduxUnauthorizedMiddleware = (store) => (next) => (action) => {
  if (action.type.includes("persist")) return next(action);

  const { payload } = action;

  if (payload && payload.request && payload.request.status === 401) {
    store.dispatch(unauthorizedRequest());
  }
  return next(action);
};
