import { handleError } from "../utils/errorHandler";
import SnackbarUtils from "../utils/snackbarUtils";

export const reduxErrorMiddleware = (store) => (next) => (action) => {
  if (action.type.includes("persist")) return next(action);

  const errors = handleError(action);
  errors.forEach((error) => SnackbarUtils.error(error.message));
  return next(action);
};
