import { IconButton, Tooltip } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { useFormikContext } from "formik";
import moment from "moment";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFormProfessionals } from "../../../../actions/forms";
import ConfirmDialog from "../../../commons/dialog/confirmDialog";
import FormikAutocomplete from "../../../commons/formik/formikAutocomplete";
import FormikDateTimePicker from "../../../commons/formik/formikDateTimePicker";
import AddAppointment from "../../accident/accidentDashboard/appointmentsCalendar/addAppointment";
import { useStyles } from "../formStyle";

const AppointmentControl = ({
  addAppointmentControl,
  appointmentModel,
  name,
  handleAppointmentSubmit,
  openAppointment,
  handleOpenAppointment,
  handleCloseAppointment,
  especialidadesProfesional,
  required,
  disabled,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { setFieldValue } = useFormikContext();

  const calendar = useSelector(
    (state) => state.accidentDashboard.appointmentsCalendar,
  );
  const professionals = useSelector((state) => state.forms.professionals);
  const accident = useSelector((state) => state.accidentDashboard.accident);

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [accidentAppointments, setAccidentAppointments] = useState(
    calendar.data.appointments?.slice(),
  );
  const [errorProfessionalsDuplicated, setErrorProfessionalsDuplicated] =
    useState(false);

  const handleOpenConfirmDialog = () => {
    setConfirmDialogOpen(true);
  };

  const handleCloseConfirmDialog = () => {
    setConfirmDialogOpen(false);
  };

  const handleConfirmCloseAppointment = () => {
    handleCloseAppointment();
    handleCloseConfirmDialog();
  };
  return (
    <div style={{ width: "100%", display: "flex" }}>
      {addAppointmentControl ? (
        <div className={classes.appointmentControl}>
          <FormikAutocomplete
            name={name}
            label={`Fecha Hora Próxima Consulta ${required ? "*" : ""}`}
            variant="outlined"
            options={accidentAppointments}
            callBack={(value) => {
              if (value) {
                handleAppointmentSubmit(value);
              } else {
                handleAppointmentSubmit({
                  fechaHora: null,
                  especialidad: null,
                  profesional: null,
                  profesionalApellidoNombre: null,
                  matricula: "",
                  observacion: "",
                  traslado: null,
                });
              }
            }}
            className={classes.input}
            returnProp="fechaHora"
            labelOption={(option) => option.fechaHora}
            formatLabelOption={(label) =>
              moment(label).format("DD/MM/YYYY HH:mm")
            }
            renderOption={(option) =>
              `${moment(option.fechaHora).format("DD/MM/YYYY HH:mm")} - ${
                option.profesionalApellidoNombre
              }`
            }
            filterOptions={(option) =>
              moment(option.fechaHora).format("DD/MM/YYYY HH:mm") +
              option.profesionalApellidoNombre
            }
            disabled={disabled}
          />
          <Tooltip
            title="Agregar Turno"
            onClick={disabled ? undefined : handleOpenAppointment}
            className={
              disabled ? classes.addAppointmentDisabled : classes.addAppointment
            }>
            <IconButton disabled={disabled}>
              <Add style={{ width: 40, height: 40 }} />
            </IconButton>
          </Tooltip>
          <AddAppointment
            open={openAppointment}
            handleClose={handleCloseAppointment}
            handleOpenConfirmDialog={handleOpenConfirmDialog}
            handleSubmit={(values, createProfessional) => {
              // Resetear error de profesionales duplicados
              setErrorProfessionalsDuplicated(false);

              // Manejar creación de un nuevo profesional
              if (createProfessional) {
                if (
                  professionals.data.some(
                    (p) =>
                      p.nombre.toUpperCase() ===
                      values.profesionalApellidoNombre.toUpperCase(),
                  )
                ) {
                  setErrorProfessionalsDuplicated(true);
                  return;
                }

                const newProfessional = {
                  nombre: values.profesionalApellidoNombre,
                  especialidad: values.especialidad,
                  especialidades: [{ especialidad: values.especialidad }],
                  nombreEspecialidad: values.nombreEspecialidad,
                  matricula: values.matricula,
                  id: "",
                };

                dispatch(setFormProfessionals(newProfessional));
              }

              // Obtener y actualizar la cita
              let appointmentAux = calendar.data.appointments?.find(
                (a) => a.fechaHora === values.fechaHora,
              );

              if (!appointmentAux) {
                // Si no existe la cita, crear una nueva
                delete values.idTurnoSiniestro;
                setAccidentAppointments((prevAppointments) => [
                  ...prevAppointments,
                  values,
                ]);
                appointmentAux = values;
              } else {
                // Si la cita ya existe, actualizarla si hay cambios
                const hasChanges =
                  values.fechaHora !== appointmentModel.fechaHora ||
                  values.profesional !== appointmentModel.profesional ||
                  values.matricula !== appointmentModel.matricula ||
                  values.observacion !== appointmentModel.observacion ||
                  values.especialidad !== appointmentModel.especialidad ||
                  values.traslado !== appointmentModel.traslado;

                if (hasChanges) {
                  Object.assign(appointmentAux, values);
                  setAccidentAppointments((prevAppointments) => [
                    ...prevAppointments,
                    values,
                  ]);
                } else {
                  Object.assign(appointmentAux, values);
                  handleCloseAppointment();
                  setAccidentAppointments((prevAppointments) => [
                    ...prevAppointments.filter(
                      (a) => a.fechaHora !== values.fechaHora,
                    ),
                    appointmentAux,
                  ]);
                }
              }

              // Actualizar valor del campo y manejar el submit de la cita
              setFieldValue(name, values.fechaHora);
              handleCloseAppointment();
              return handleAppointmentSubmit({
                ...appointmentAux,
                fromDB: false,
              });
            }}
            appointmentModel={appointmentModel}
            especialidadesProfesional={especialidadesProfesional.options}
            artId={accident.mlCloudCoreArtId}
            errorProfessionalsDuplicated={errorProfessionalsDuplicated}
            openInCalendar={false}
          />
        </div>
      ) : (
        <FormikDateTimePicker
          name={name}
          label={`Fecha Hora Próxima Consulta ${required ? "*" : ""}`}
          inputVariant="outlined"
          disablePast
          className={classes.input}
          disabled={disabled}
          autoComplete="off"
        />
      )}
      <div>
        <ConfirmDialog
          open={confirmDialogOpen}
          handleClose={handleCloseConfirmDialog}
          handleConfirm={handleConfirmCloseAppointment}
          title="CERRAR VENTANA"
          text="¿Está seguro que desea cancelar esta operación? En caso de aceptar, se perderá la información ingresada"
        />
      </div>
    </div>
  );
};

export default AppointmentControl;
