import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDocuments } from "../../../../actions/accidentDashboard";
import {
  correctConnectivityErrorAction,
  getAllConnectivityErrors,
  getConnectivityErrors,
} from "../../../../actions/connectivityResponse";
import { getFormProfessionals, postForm } from "../../../../actions/forms";
import { convertDateTimeToNet } from "../../../../utils/datetimeUtils";
import CircularLoading from "../../../commons/loadingData/circularLoading";
import Idr from "./idr";
import IdrConfirmDialog from "./idrConfirmDialog";

const initialIdrModel = {
  fechaHora: null,
  nroFormularioFPA: null,
  detalle: "",
  profesionalApellidoNombre: null,
  profesionalMnMp: "",
  especialidadProfesional: "",
  especialidadInforme: "",
  informacionSensible: false,
};

const IdrContainer = ({
  handleClose,
  isView,
  setLoaded,
  handleOpenConfirmCloseDialog,
  selectedDocument,
  fromErrorDialog,
  fromErroresConectividad,
}) => {
  const dispatch = useDispatch();

  const accident = useSelector((state) => state.accidentDashboard.accident);
  const saveForm = useSelector((state) => state.forms.saveForm);
  const model = useSelector((state) => state.forms.model);
  const form = useSelector((state) => state.forms.form);
  const professionals = useSelector((state) => state.forms.professionals);
  const idr = useSelector((state) => state.forms.idr);
  const time = useSelector(
    (state) => state.connectivityResponse.connectivityErrors.time,
  );
  const [idrModel, setIdrModel] = useState(null);
  const [files, setFiles] = useState([]);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [formValues, setFormValues] = useState({});

  const isLoading =
    model.isFetching ||
    saveForm.isFetching ||
    form.isFetching ||
    professionals.isFetching ||
    (!isView && !idrModel);

  useEffect(() => {
    if (isView && !form.isFetching) {
      setFiles(form.data.form.adjuntos);
    }
  }, [form.isFetching]);

  useEffect(() => {
    if (!model.isFetching && !isView) {
      setIdrModel(() => ({
        ...initialIdrModel,
        nroFormularioFPA: idr.data.id
          ? idr.data.id
          : initialIdrModel.nroFormularioFPA,
        fechaHora: new Date(),
        profesionalApellidoNombre: model.data.fields.profesionalApellidoNombre
          .options
          ? model.data.fields.profesionalApellidoNombre.options
          : initialIdrModel.profesionalApellidoNombre,
      }));
    }
  }, [model.isFetching]);

  useEffect(() => {
    if (!isLoading && !!setLoaded) {
      setLoaded(true);
    }
  }, [
    model.isFetching,
    saveForm.isFetching,
    form.isFetching,
    professionals.isFetching,
  ]);

  const handleSubmitForm = (values) => {
    let fpa;
    if (isView) {
      fpa = form.data.model.fields.nroFormularioFPA.options.find(
        (f) => f.id === values.nroFormularioFPA,
      );
    } else {
      fpa = model.data.fields.nroFormularioFPA.options.find(
        (f) => f.id === values.nroFormularioFPA,
      );
    }
    if (fpa && fpa.existeFormularioAsociado) {
      handleOpenConfirmDialog(values);
    } else {
      handleSubmit(values);
    }
  };

  const handleOpenConfirmDialog = (values) => {
    setFormValues(values);
    setOpenConfirmDialog(true);
  };

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
    setFormValues({});
  };

  const handleSubmit = (form) => {
    const filesAux = [];
    files.forEach((e) =>
      filesAux.push({ ...e, nombre: e.filename || e.nombre, base64: e.value }),
    );
    const model = {
      ...form,
      numeroSiniestroPrestador: accident.idSiniestro,
      adjuntos: filesAux,
      fechaHora: convertDateTimeToNet(form.fechaHora),
    };
    if (fromErroresConectividad) {
      model.numeroSiniestroPrestador = selectedDocument.siniestro;
    }
    handleCloseConfirmDialog();

    dispatch(postForm("idr", model, accident.mlCloudCoreArtId))
      .then(() => {
        dispatch(getFormProfessionals(accident.mlCloudCoreArtId));
      })
      .then(() => {
        if (fromErrorDialog) {
          dispatch(
            correctConnectivityErrorAction(
              selectedDocument.idRespError,
              accident.mlCloudCoreArtId,
            ),
          ).then((res) => {
            dispatch(
              getDocuments(accident.idSiniestro, accident.mlCloudCoreArtId),
            );
            dispatch(
              getConnectivityErrors(
                selectedDocument.nroTransaccion,
                accident.mlCloudCoreArtId,
              ),
            ).then((res) => handleClose(true, false));
          });
        } else {
          handleClose(true, false);
        }

        if (fromErroresConectividad) {
          dispatch(
            correctConnectivityErrorAction(
              selectedDocument.idRespError,
              accident.mlCloudCoreArtId,
            ),
          ).then((res) => {
            dispatch(getAllConnectivityErrors(time));
          });
        }
      })
      .catch(() => handleClose(true, true));
  };

  return (
    <div style={{ width: "100%" }}>
      {isLoading ? (
        <div style={{ marginTop: 100 }}>
          <CircularLoading />
        </div>
      ) : isView ? (
        <Idr
          handleClose={handleClose}
          files={files}
          setFiles={setFiles}
          handleSubmit={handleSubmitForm}
          model={form.data.model}
          idrModel={form.data.form}
          roles={form.data.roles}
          fromErrorDialog={fromErrorDialog}
          fromErroresConectividad={fromErroresConectividad}
          selectedDocument={selectedDocument}
        />
      ) : (
        <Idr
          handleClose={handleOpenConfirmCloseDialog}
          files={files}
          setFiles={setFiles}
          handleSubmit={handleSubmitForm}
          model={model.data}
          idrModel={idrModel}
        />
      )}
      <IdrConfirmDialog
        handleClose={handleCloseConfirmDialog}
        open={openConfirmDialog}
        title="CONFIRMAR FORMULARIO"
        text={`El Pedido de Autorización seleccionado ya se encuentra asociado a otro Formulario.\n\n¿Está seguro que desea asociar adicionalmente este Formulario?`}
        handleSubmit={handleSubmit}
        form={formValues}
      />
    </div>
  );
};

export default IdrContainer;
