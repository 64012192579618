import {
  CANCEL_FPT,
  CLEAR_AFFECTED_ZONES,
  CLEAR_FORM,
  CLEAR_FPT,
  CLEAR_IDR_IN_VIEW,
  CLEAR_IDTURNOS,
  GET_AFFECTED_ZONES,
  GET_ATTACHMENT_BASE64,
  GET_FORM,
  GET_FORM_DIAGNOSIS,
  GET_FORM_EMPLOYERS,
  GET_FORM_MODEL,
  GET_FORM_NATURALEZAS_LESION,
  GET_FORM_PRESTACIONES,
  GET_FORM_PROFESSIONALS,
  GET_FORM_PROFESSIONAL_SPECIALTIES,
  GET_FORM_SPECIALTIES_DIAGNOSIS,
  GET_FORM_ZONAS_AFECTADAS,
  OPEN_FPT_IN_AUTH,
  OPEN_IDR_IN_VIEW,
  POST_FORM,
  POST_SIGN_FORM,
  SET_CHANGE_DOCUMENT,
  SET_CHANGE_OPEN_DOCUMENT_DETAIL,
  SET_CLOSE_ADD_FORM,
  SET_FORM_PROFESSIONALS,
  SET_FPT,
  SET_OPEN_ADD_FORM,
  UPDATE_FORM_PROFESSIONALS,
} from "../actions/forms";

const INITIAL_STATE = {
  changeDocument: false,
  formToAdd: { formulario: null, nombre: null },
  openAddForm: false,
  openAccidentDocumentsDetail: false,
  postImportIet: {
    isFetching: false,
    didInvalidate: false,
    data: {},
  },
  model: {
    isFetching: false,
    didInvalidate: false,
    data: {},
  },
  saveForm: {
    isFetching: false,
    didInvalidate: false,
    data: {},
  },
  form: {
    isFetching: false,
    didInvalidate: false,
    data: {},
  },
  professionals: {
    isFetching: false,
    didInvalidate: false,
    data: [],
  },
  professionalSpecialties: {
    isFetching: false,
    didInvalidate: false,
    data: [],
  },
  diagnosis: {
    isFetching: false,
    didInvalidate: false,
    data: [],
  },
  naturalezasLesion: {
    isFetching: false,
    didInvalidate: false,
    data: [],
  },
  zonasAfectadas: {
    isFetching: false,
    didInvalidate: false,
    data: [],
  },
  attachment: {
    isFetching: false,
    didInvalidate: false,
    data: {},
  },
  prestaciones: {
    isFetching: false,
    didInvalidate: false,
    data: [],
  },
  affectedZones: {
    isFetching: false,
    didInvalidate: false,
    data: [],
  },
  employers: {
    isFetching: false,
    didInvalidate: false,
    data: [],
  },
  specialtiesDiagnosis: {
    isFetching: false,
    didInvalidate: false,
    data: [],
  },
  postSignForm: {
    isFetching: false,
    didInvalidate: false,
    data: {},
  },
  fpt: {
    isOpen: false,
    isOutsideAccident: false,
    wasCanceled: false,
    data: {},
  },
  listIdAppointmentsForm: {
    data: [],
  },
  idr: {
    isOpen: false,
    data: {},
  },
};

const forms = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case OPEN_IDR_IN_VIEW:
      return {
        ...state,
        idr: {
          ...state.idr,
          isOpen: true,
          data: action.payload,
        },
      };
    case CLEAR_IDR_IN_VIEW:
      return {
        ...state,
        idr: {
          ...state.idr,
          isOpen: false,
          data: {},
        },
      };
    case OPEN_FPT_IN_AUTH:
      return {
        ...state,
        fpt: {
          ...state.fpt,
          isOutsideAccident: true,
        },
      };
    case CANCEL_FPT:
      return {
        ...state,
        fpt: {
          ...state.fpt,
          wasCanceled: true,
        },
      };
    case SET_FPT:
      return {
        ...state,
        fpt: {
          isOpen: true,
          data: action.payload,
        },
      };
    case CLEAR_FPT:
      return {
        ...state,
        fpt: {
          ...INITIAL_STATE.fpt,
        },
      };
    case `${GET_FORM_MODEL}_REJECTED`:
      return {
        ...state,
        model: {
          ...state.model,
          isFetching: false,
          didInvalidate: true,
        },
      };
    case `${GET_FORM_MODEL}_PENDING`:
      return {
        ...state,
        model: {
          ...state.model,
          isFetching: true,
          didInvalidate: false,
        },
      };
    case `${GET_FORM_MODEL}_FULFILLED`: {
      return {
        ...state,
        model: {
          ...state.model,
          isFetching: false,
          didInvalidate: false,
          data: action.payload.data,
        },
      };
    }
    case `${GET_FORM_NATURALEZAS_LESION}_REJECTED`:
      return {
        ...state,
        naturalezasLesion: {
          ...state.naturalezasLesion,
          isFetching: false,
          didInvalidate: true,
        },
      };
    case `${GET_FORM_NATURALEZAS_LESION}_PENDING`:
      return {
        ...state,
        naturalezasLesion: {
          ...state.naturalezasLesion,
          isFetching: true,
          didInvalidate: false,
        },
      };
    case `${GET_FORM_NATURALEZAS_LESION}_FULFILLED`: {
      return {
        ...state,
        naturalezasLesion: {
          ...state.naturalezasLesion,
          isFetching: false,
          didInvalidate: false,
          data: action.payload.data,
        },
      };
    }
    case `${GET_FORM_ZONAS_AFECTADAS}_REJECTED`:
      return {
        ...state,
        zonasAfectadas: {
          ...state.zonasAfectadas,
          isFetching: false,
          didInvalidate: true,
        },
      };
    case `${GET_FORM_ZONAS_AFECTADAS}_PENDING`:
      return {
        ...state,
        zonasAfectadas: {
          ...state.zonasAfectadas,
          isFetching: true,
          didInvalidate: false,
        },
      };
    case `${GET_FORM_ZONAS_AFECTADAS}_FULFILLED`: {
      return {
        ...state,
        zonasAfectadas: {
          ...state.zonasAfectadas,
          isFetching: false,
          didInvalidate: false,
          data: action.payload.data,
        },
      };
    }
    case `${GET_FORM_DIAGNOSIS}_REJECTED`:
      return {
        ...state,
        diagnosis: {
          ...state.diagnosis,
          isFetching: false,
          didInvalidate: true,
        },
      };
    case `${GET_FORM_DIAGNOSIS}_PENDING`:
      return {
        ...state,
        diagnosis: {
          ...state.diagnosis,
          isFetching: true,
          didInvalidate: false,
        },
      };
    case `${GET_FORM_DIAGNOSIS}_FULFILLED`: {
      return {
        ...state,
        diagnosis: {
          ...state.diagnosis,
          isFetching: false,
          didInvalidate: false,
          data: action.payload.data,
        },
      };
    }
    case `${GET_FORM_PROFESSIONALS}_REJECTED`:
      return {
        ...state,
        professionals: {
          ...state.professionals,
          isFetching: false,
          didInvalidate: true,
        },
      };
    case `${GET_FORM_PROFESSIONALS}_PENDING`:
      return {
        ...state,
        professionals: {
          ...state.professionals,
          isFetching: true,
          didInvalidate: false,
        },
      };
    case `${GET_FORM_PROFESSIONALS}_FULFILLED`: {
      return {
        ...state,
        professionals: {
          ...state.professionals,
          isFetching: false,
          didInvalidate: false,
          data: action.payload.data,
        },
      };
    }
    case SET_FORM_PROFESSIONALS: {
      const professionalsDuplicated = state.professionals.data;
      const proIndex = professionalsDuplicated.findIndex(
        (p) => p.id === action.payload.id,
      );
      if (proIndex === -1) {
        professionalsDuplicated.push(action.payload);
      } else {
        professionalsDuplicated[proIndex] = action.payload;
      }

      return {
        ...state,
        professionals: {
          ...state.professionals,
          data: professionalsDuplicated,
        },
      };
    }
    case UPDATE_FORM_PROFESSIONALS: {
      const professionalsDuplicated = state.professionals.data;
      const { especialidad, matricula, id } = action.payload;
      const proIndex = professionalsDuplicated.findIndex((p) => p.id === id);
      if (proIndex !== -1) {
        professionalsDuplicated[proIndex].especialidad = especialidad;
        professionalsDuplicated[proIndex].matricula = matricula;
        const repeatSpecialty = professionalsDuplicated[
          proIndex
        ].especialidades.find(
          (e) =>
            e.especialidad.toUpperCase().trim() ===
            especialidad.toUpperCase().trim(),
        );
        if (!repeatSpecialty) {
          professionalsDuplicated[proIndex].especialidades.push({
            especialidad,
          });
        }
      }
      return {
        ...state,
        professionals: {
          ...state.professionals,
          data: professionalsDuplicated,
        },
      };
    }
    case `${GET_FORM_PROFESSIONAL_SPECIALTIES}_REJECTED`:
      return {
        ...state,
        professionalSpecialties: {
          ...state.professionalSpecialties,
          isFetching: false,
          didInvalidate: true,
        },
      };
    case `${GET_FORM_PROFESSIONAL_SPECIALTIES}_PENDING`:
      return {
        ...state,
        professionalSpecialties: {
          ...state.professionalSpecialties,
          isFetching: true,
          didInvalidate: false,
        },
      };
    case `${GET_FORM_PROFESSIONAL_SPECIALTIES}_FULFILLED`: {
      return {
        ...state,
        professionalSpecialties: {
          ...state.professionalSpecialties,
          isFetching: false,
          didInvalidate: false,
          data: action.payload.data,
        },
      };
    }
    case CLEAR_FORM: {
      return INITIAL_STATE;
    }
    case `${POST_FORM}_REJECTED`:
      return {
        ...state,
        saveForm: {
          ...state.saveForm,
          isFetching: false,
          didInvalidate: true,
        },
      };
    case `${POST_FORM}_PENDING`:
      return {
        ...state,
        saveForm: {
          ...state.saveForm,
          isFetching: true,
          didInvalidate: false,
        },
      };
    case `${POST_FORM}_FULFILLED`: {
      return {
        ...state,
        saveForm: {
          ...state.saveForm,
          isFetching: false,
          didInvalidate: false,
          data: action.payload.data,
        },
        listIdAppointmentsForm: {
          data: action.payload.data.idTurnosArray,
        },
      };
    }
    case CLEAR_IDTURNOS: {
      return {
        ...state,
        listIdAppointmentsForm: {
          data: [],
        },
      };
    }
    case `${GET_FORM}_REJECTED`:
      return {
        ...state,
        form: {
          ...state.form,
          isFetching: false,
          didInvalidate: true,
        },
      };
    case `${GET_FORM}_PENDING`:
      return {
        ...state,
        form: {
          ...state.form,
          isFetching: true,
          didInvalidate: false,
        },
      };
    case `${GET_FORM}_FULFILLED`: {
      return {
        ...state,
        form: {
          ...state.form,
          isFetching: false,
          didInvalidate: false,
          data: action.payload.data,
        },
      };
    }
    case `${GET_ATTACHMENT_BASE64}_REJECTED`:
      return {
        ...state,
        attachment: {
          ...state.attachment,
          isFetching: false,
          didInvalidate: true,
        },
      };
    case `${GET_ATTACHMENT_BASE64}_PENDING`:
      return {
        ...state,
        attachment: {
          ...state.attachment,
          isFetching: true,
          didInvalidate: false,
        },
      };
    case `${GET_ATTACHMENT_BASE64}_FULFILLED`: {
      return {
        ...state,
        attachment: {
          ...state.attachment,
          isFetching: false,
          didInvalidate: false,
          data: action.payload.data,
        },
      };
    }
    case `${GET_FORM_PRESTACIONES}_REJECTED`:
      return {
        ...state,
        prestaciones: {
          ...state.prestaciones,
          isFetching: false,
          didInvalidate: true,
        },
      };
    case `${GET_FORM_PRESTACIONES}_PENDING`:
      return {
        ...state,
        prestaciones: {
          ...state.prestaciones,
          isFetching: true,
          didInvalidate: false,
        },
      };
    case `${GET_FORM_PRESTACIONES}_FULFILLED`: {
      return {
        ...state,
        prestaciones: {
          ...state.prestaciones,
          isFetching: false,
          didInvalidate: false,
          data: action.payload.data,
        },
      };
    }
    case `${GET_AFFECTED_ZONES}_REJECTED`:
      return {
        ...state,
        affectedZones: {
          ...state.affectedZones,
          isFetching: false,
          didInvalidate: true,
        },
      };
    case `${GET_AFFECTED_ZONES}_PENDING`:
      return {
        ...state,
        affectedZones: {
          ...state.affectedZones,
          isFetching: true,
          didInvalidate: false,
        },
      };
    case `${GET_AFFECTED_ZONES}_FULFILLED`: {
      return {
        ...state,
        affectedZones: {
          ...state.affectedZones,
          isFetching: false,
          didInvalidate: false,
          data: action.payload.data,
        },
      };
    }
    case `${CLEAR_AFFECTED_ZONES}`: {
      return {
        ...state,
        affectedZones: {
          ...state.affectedZones,
          data: [],
        },
      };
    }
    case `${GET_FORM_EMPLOYERS}_REJECTED`:
      return {
        ...state,
        employers: {
          ...state.employers,
          isFetching: false,
          didInvalidate: true,
        },
      };
    case `${GET_FORM_EMPLOYERS}_PENDING`:
      return {
        ...state,
        employers: {
          ...state.employers,
          isFetching: true,
          didInvalidate: false,
        },
      };
    case `${GET_FORM_EMPLOYERS}_FULFILLED`: {
      return {
        ...state,
        employers: {
          ...state.employers,
          isFetching: false,
          didInvalidate: false,
          data: action.payload.data,
        },
      };
    }
    case `${GET_FORM_SPECIALTIES_DIAGNOSIS}_REJECTED`:
      return {
        ...state,
        specialtiesDiagnosis: {
          ...state.specialtiesDiagnosis,
          isFetching: false,
          didInvalidate: true,
        },
      };
    case `${GET_FORM_SPECIALTIES_DIAGNOSIS}_PENDING`:
      return {
        ...state,
        specialtiesDiagnosis: {
          ...state.specialtiesDiagnosis,
          isFetching: true,
          didInvalidate: false,
        },
      };
    case `${GET_FORM_SPECIALTIES_DIAGNOSIS}_FULFILLED`: {
      return {
        ...state,
        specialtiesDiagnosis: {
          ...state.specialtiesDiagnosis,
          isFetching: false,
          didInvalidate: false,
          data: action.payload.data,
        },
      };
    }
    case `${POST_SIGN_FORM}_REJECTED`:
      return {
        ...state,
        postSignForm: {
          ...state.postSignForm,
          isFetching: false,
          didInvalidate: true,
        },
      };
    case `${POST_SIGN_FORM}_PENDING`:
      return {
        ...state,
        postSignForm: {
          ...state.postSignForm,
          isFetching: true,
          didInvalidate: false,
        },
      };
    case `${POST_SIGN_FORM}_FULFILLED`: {
      return {
        ...state,
        postSignForm: {
          ...state.postSignForm,
          isFetching: false,
          didInvalidate: false,
          data: action.payload.data,
        },
      };
    }
    case `${SET_OPEN_ADD_FORM}`: {
      return {
        ...state,
        openAddForm: true,
        formToAdd: action.payload,
      };
    }
    case `${SET_CLOSE_ADD_FORM}`: {
      return {
        ...state,
        openAddForm: false,
      };
    }
    case `${SET_CHANGE_DOCUMENT}`: {
      return {
        ...state,
        changeDocument: action.payload,
      };
    }
    case `${SET_CHANGE_OPEN_DOCUMENT_DETAIL}`: {
      return {
        ...state,
        openAccidentDocumentsDetail: action.payload,
      };
    }
    default:
      return state;
  }
};

export default forms;
